import axios from "axios";
import { AppDispatch } from "../redux/store";
import { GET_PRICING_OBJECT } from "./types";
import { pricingObjectProd } from "../Components/Apps/Pricing/utils/static/pricingObjectProd";
import { pricingObjectDev } from "../Components/Apps/Pricing/utils/static/pricingObjectDev";
import { getBackendUrl } from "../utils/routes";

const getPricingObjectByEnvironment = () => {
    if (process.env.NODE_ENV === "production" && process.env.REACT_APP_ENV === "prod") {
        console.log("Return prod object");
        return pricingObjectProd;
    }
    console.log("Return dev object");
    return pricingObjectDev;
};

// if this is true, a call is sent to the backend every time somebody accesses the pricing page
//  if this is set to false it uses the manually "cached" pricing object within this repo
const FETCH_LATEST_PRICING_FROM_BACKEND = false;

export const getPricingObject =
    (brandName: TAccountType, environment: TEnv = "prod") =>
    (dispatch: AppDispatch) => {
        if (FETCH_LATEST_PRICING_FROM_BACKEND) {
            // Enable for fetching latest pricing from backend
            const API_URL = getBackendUrl(environment, brandName);
            axios
                .get(`${API_URL}/api/stripe/get_pricing_object/`)
                .then((res) => {
                    console.log("res.data", res.data);
                    dispatch({
                        type: GET_PRICING_OBJECT,
                        payload: res.data,
                    });
                })
                .catch((error) => {
                    // Log to app insights
                    // trackException(error);
                    console.error(error);
                });
        } else {
            dispatch({
                type: GET_PRICING_OBJECT,
                payload: getPricingObjectByEnvironment(),
            });
        }
    };
