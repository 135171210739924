import React, { useState, useEffect } from "react";
import { getInitialSliderValue } from "../../Apps/Pricing/utils/library";
import { getMaximumParticipantsInBillingyCycle } from "./utils";
import clsx from "clsx";

// mui
import theme from "../../theme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import type { Mark } from "@material-ui/core/Slider";
import Grid from "@material-ui/core/Grid";

// components
import PricingSlider from "./PricingSlider";
import CurrencyToggle from "./CurrencyToggle";
import BillingSwitch from "./BillingSwitch";
import PricingCard from "../../Apps/Pricing/PricingCard";

type Props = {
    // The pricing object
    pricing: VbPricing.PricingObject;
    // Method that is called when a plan is selected. Only needed if pricingCardSize = "small"
    onPlanSelect?: ReduxStoreRegister.TPlanSelect;
    // If a price is selected, this is passed in for the initial selection
    selectedPrice?: ReduxStoreRegister.ISelectedPriceObjectWithMetadata | undefined;
};

export default function PlanSelection(props: Props) {
    // state hooks
    const [billingCycle, setBillingCycle] = useState<VbPricing.billingCycle>("yearly");
    const [selectedCurrency, setSelectedCurrency] = useState<VbPricing.currencyType>("usd");
    const [sliderValue, setSliderValue] = useState(
        getInitialSliderValue(props.pricing, billingCycle, props.selectedPrice)
    );
    const [sliderMarks, setSliderMarks] = useState<Mark[] | undefined>(undefined);
    const [numSelectedParticipants, setNumSelectedParticipants] = useState<number>(
        props.selectedPrice
            ? props.selectedPrice.numSelectedParticipants
            : props.pricing.bundles.pro.products[billingCycle]?.find((elem, index) => index === 0)?.participant_count ||
                  25
    );
    const [maximumParticipantsInBillingyCycle, setMaximumParticipantsInBillingyCycle] = useState<{
        monthly: number;
        yearly: number;
    }>(getMaximumParticipantsInBillingyCycle(props.pricing));

    // layout hooks
    const size__xs_down = useMediaQuery(theme.breakpoints.down("xs"));
    const size__sm_down = useMediaQuery(theme.breakpoints.down("sm"));

    useEffect(() => {
        sliderMarks && updateNumSelectedParticipants(sliderValue, sliderMarks);
    }, [sliderMarks]);

    /**
     * Update the component state with the new slider value and the new amount of recipients
     * @param newValue
     * @param marks
     */
    const updateNumSelectedParticipants = (newValue: number, marks: Mark[]) => {
        let _numberParticipants: number = isNaN(Number(marks[newValue - 1]?.label))
            ? -1
            : Number(marks[newValue - 1].label);
        if (numSelectedParticipants !== _numberParticipants) {
            setNumSelectedParticipants(_numberParticipants);
            setSliderValue(newValue);
        }
    };

    /**
     * Handler for changes of the recipient slider
     * @param event
     * @param newValue
     * @param marks
     */
    const handleSliderChange = (event: React.ChangeEvent<{}>, newValue: number, marks: Mark[]) => {
        updateNumSelectedParticipants(newValue, marks);
    };

    return (
        <div className="mt-2">
            <PricingSlider
                onChange={handleSliderChange}
                // the slider value (1..8 for eight products)
                value={sliderValue}
                billingCycle={billingCycle}
                pricing={props.pricing}
                hideTooltip
                setSliderMarks={setSliderMarks}
            />
            <div className="mt-4" />
            <BillingSwitch
                numSelectedParticipants={numSelectedParticipants}
                sliderMarks={sliderMarks}
                sliderValue={sliderValue}
                billingCycle={billingCycle}
                setBillingCycle={setBillingCycle}
                maximumParticipantsInBillingyCycle={maximumParticipantsInBillingyCycle}
                size={"large"}
            />
            <>
                <div
                    className={clsx(
                        "d-flex mt-5",
                        size__sm_down ? "align-items-center flex-column" : "justify-content-between flex-row"
                    )}
                >
                    {[
                        {
                            disabled: sliderValue > 1,
                            pricing: props.pricing.bundles.starter,
                            isCustom: false,
                        },
                        {
                            pricing: props.pricing.bundles.pro,
                            isCustom: sliderValue > props.pricing.bundles.pro.products.monthly.length,
                            featured: true,
                        },
                        {
                            pricing: props.pricing.bundles.expert,
                            isCustom: sliderValue > props.pricing.bundles.expert.products.monthly.length,
                        },
                    ].map((elem, idx) => (
                        <React.Fragment key={idx}>
                            <PricingCard
                                disabled={elem.disabled ?? false}
                                featured={elem.featured ?? false}
                                numSelectedParticipants={numSelectedParticipants}
                                pricing={elem.pricing}
                                billingCycle={billingCycle}
                                selectedCurrency={selectedCurrency}
                                selectedSliderValue={sliderValue}
                                isCustom={elem.isCustom}
                                className={size__sm_down && idx !== 2 ? "mb-4" : ""}
                            />
                            {size__sm_down && [0, 1].includes(idx) && <div className={"mb-2"} />}
                        </React.Fragment>
                    ))}
                </div>
                <div className="d-flex justify-content-end mt-2">
                    <CurrencyToggle
                        selectedCurrency={selectedCurrency}
                        setSelectedCurrency={setSelectedCurrency}
                        size="large"
                    />
                </div>
            </>
        </div>
    );
}
