import theme from "../../../theme";

export const stretchedParagraph = {
    fontSize: 19,
    color: theme.palette.grey[900],
    fontWeight: 400,
    letterSpacing: "2.7px",
    marginBottom: 8,
};
export const halfStretchedParagraph = {
    ...stretchedParagraph,
    letterSpacing: "1.2px",
};
export const smallParagraph = {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "160%",
    color: theme.palette.grey[600],
};

export const containerBorderStyles = {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: 4,
};
export const getBoxPadding = (size__md_down: boolean) => (size__md_down ? "8px 16px" : "16px 32px");
