const RightArrowIcon = () => {
    return (
        <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_5628_2178)">
                <path
                    d="M35.75 19.5C35.75 10.53 28.47 3.25 19.5 3.25C10.53 3.25 3.25 10.53 3.25 19.5C3.25 28.47 10.53 35.75 19.5 35.75C28.47 35.75 35.75 28.47 35.75 19.5ZM6.5 19.5C6.5 12.3175 12.3175 6.5 19.5 6.5C26.6825 6.5 32.5 12.3175 32.5 19.5C32.5 26.6825 26.6825 32.5 19.5 32.5C12.3175 32.5 6.5 26.6825 6.5 19.5ZM26 19.5L19.5 26L17.2087 23.7087L19.7763 21.125H13V17.875H19.7763L17.1925 15.2913L19.5 13L26 19.5Z"
                    fill="#007BFF"
                />
                <circle cx="19.5" cy="19.5" r="14" stroke="white" stroke-width="6" />
            </g>
            <defs>
                <clipPath id="clip0_5628_2178">
                    <rect width="39" height="39" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default RightArrowIcon;
