export const pricingObjectProd = {
    bundles: {
        starter: {
            products: {
                monthly: [
                    {
                        participant_count: 25,
                        prices: [
                            {
                                currency: "usd",
                                amount: 1500,
                                stripe_price_id: "price_1KWKT0BlUauxnBUzBDzR6mgJ",
                            },
                            {
                                currency: "eur",
                                amount: 1200,
                                stripe_price_id: "price_1KWKSgBlUauxnBUz67dHhY6u",
                            },
                        ],
                    },
                ],
                yearly: [
                    {
                        participant_count: 300,
                        prices: [
                            {
                                currency: "usd",
                                amount: 10800,
                                stripe_price_id: "price_1KWKW1BlUauxnBUzV3H8uYR5",
                            },
                            {
                                currency: "eur",
                                amount: 9600,
                                stripe_price_id: "price_1KWKWIBlUauxnBUzRBEqyrO3",
                            },
                        ],
                    },
                ],
            },
            features: {
                id: 16,
                unique_name: "Starter",
                public_name: "Starter",
                max_orga_slugs: 1,
                max_badge_campaigns: 1,
                whitelabel_email: false,
                whitelabel_badge: true,
                whitelabel_landing_page: false,
                online_certificates: false,
                credential_validation: false,
                custom_url_slugs: false,
                dedicated_customer_success: false,
                open_api_access: false,
                zapier_api_access: false,
                email_retargeting: false,
                custom_analytics_landing_page: false,
                custom_fields_participants: false,
                statistics_access: "basic",
                support: "basic",
                auth_methods: "all",
                added: "2021-03-25T17:11:59Z",
            },
        },
        pro: {
            products: {
                monthly: [
                    {
                        participant_count: 25,
                        prices: [
                            {
                                currency: "usd",
                                amount: 4900,
                                stripe_price_id: "price_1JMYlABlUauxnBUzM9qCeuQ3",
                            },
                            {
                                currency: "eur",
                                amount: 3900,
                                stripe_price_id: "price_1JMYlABlUauxnBUz4UTg49bH",
                            },
                        ],
                    },
                    {
                        participant_count: 50,
                        prices: [
                            {
                                currency: "usd",
                                amount: 4900,
                                stripe_price_id: "price_1JMYlABlUauxnBUzM9qCeuQ3",
                            },
                            {
                                currency: "eur",
                                amount: 3900,
                                stripe_price_id: "price_1JMYlABlUauxnBUz4UTg49bH",
                            },
                        ],
                    },
                    {
                        participant_count: 100,
                        prices: [
                            {
                                currency: "eur",
                                amount: 4500,
                                stripe_price_id: "price_1JMYkrBlUauxnBUzTDJqr8Up",
                            },
                            {
                                currency: "usd",
                                amount: 5500,
                                stripe_price_id: "price_1JMYkqBlUauxnBUzaosKnEWQ",
                            },
                        ],
                    },
                    {
                        participant_count: 150,
                        prices: [
                            {
                                currency: "usd",
                                amount: 6500,
                                stripe_price_id: "price_1JMYkaBlUauxnBUzS9F1INAd",
                            },
                            {
                                currency: "eur",
                                amount: 5200,
                                stripe_price_id: "price_1JMYkaBlUauxnBUzphcEN0Dq",
                            },
                        ],
                    },
                    {
                        participant_count: 200,
                        prices: [
                            {
                                currency: "eur",
                                amount: 6000,
                                stripe_price_id: "price_1JMYkBBlUauxnBUzYgNT1ZIk",
                            },
                            {
                                currency: "usd",
                                amount: 7500,
                                stripe_price_id: "price_1JMYkBBlUauxnBUzypVQ6yyK",
                            },
                        ],
                    },
                    {
                        participant_count: 250,
                        prices: [
                            {
                                currency: "eur",
                                amount: 6800,
                                stripe_price_id: "price_1JMYjzBlUauxnBUzxiKachB2",
                            },
                            {
                                currency: "usd",
                                amount: 8500,
                                stripe_price_id: "price_1JMYjzBlUauxnBUzfgIn9P0V",
                            },
                        ],
                    },
                    {
                        participant_count: 300,
                        prices: [
                            {
                                currency: "eur",
                                amount: 7600,
                                stripe_price_id: "price_1JMYjeBlUauxnBUzDKAHzX9C",
                            },
                            {
                                currency: "usd",
                                amount: 9500,
                                stripe_price_id: "price_1JMYjeBlUauxnBUzI19bxDZv",
                            },
                        ],
                    },
                ],
                yearly: [
                    {
                        participant_count: 300,
                        prices: [
                            {
                                currency: "usd",
                                amount: 42000,
                                stripe_price_id: "price_1JMYlABlUauxnBUzxu6OFaLH",
                            },
                            {
                                currency: "eur",
                                amount: 33600,
                                stripe_price_id: "price_1JMYlABlUauxnBUzu1KTqqeS",
                            },
                        ],
                    },
                    {
                        participant_count: 600,
                        prices: [
                            {
                                currency: "usd",
                                amount: 42000,
                                stripe_price_id: "price_1JMYlABlUauxnBUzxu6OFaLH",
                            },
                            {
                                currency: "eur",
                                amount: 33600,
                                stripe_price_id: "price_1JMYlABlUauxnBUzu1KTqqeS",
                            },
                        ],
                    },
                    {
                        participant_count: 1200,
                        prices: [
                            {
                                currency: "eur",
                                amount: 42000,
                                stripe_price_id: "price_1JMYkqBlUauxnBUzCwJGj6Bz",
                            },
                            {
                                currency: "usd",
                                amount: 54000,
                                stripe_price_id: "price_1JMYkqBlUauxnBUzpXb2eqD7",
                            },
                        ],
                    },
                    {
                        participant_count: 1800,
                        prices: [
                            {
                                currency: "eur",
                                amount: 48000,
                                stripe_price_id: "price_1JMYkaBlUauxnBUzG1nEq6vO",
                            },
                            {
                                currency: "usd",
                                amount: 60000,
                                stripe_price_id: "price_1JMYkaBlUauxnBUzTnzKraTu",
                            },
                        ],
                    },
                    {
                        participant_count: 2400,
                        prices: [
                            {
                                currency: "eur",
                                amount: 54000,
                                stripe_price_id: "price_1JMYkBBlUauxnBUzZOatXGhV",
                            },
                            {
                                currency: "usd",
                                amount: 72000,
                                stripe_price_id: "price_1JMYkBBlUauxnBUzbqAwvBRZ",
                            },
                        ],
                    },
                    {
                        participant_count: 3000,
                        prices: [
                            {
                                currency: "eur",
                                amount: 60000,
                                stripe_price_id: "price_1JMYjzBlUauxnBUz4NYxWt25",
                            },
                            {
                                currency: "usd",
                                amount: 78000,
                                stripe_price_id: "price_1JMYjyBlUauxnBUzextEgGo6",
                            },
                        ],
                    },
                    {
                        participant_count: 3600,
                        prices: [
                            {
                                currency: "eur",
                                amount: 72000,
                                stripe_price_id: "price_1JMYjeBlUauxnBUzrSzyYbEK",
                            },
                            {
                                currency: "usd",
                                amount: 90000,
                                stripe_price_id: "price_1JMYjeBlUauxnBUzp5lYowsT",
                            },
                        ],
                    },
                ],
            },
            features: {
                id: 17,
                unique_name: "Pro",
                public_name: "Pro",
                max_orga_slugs: 1,
                max_badge_campaigns: 5,
                whitelabel_email: true,
                whitelabel_badge: true,
                whitelabel_landing_page: true,
                online_certificates: true,
                credential_validation: true,
                custom_url_slugs: true,
                dedicated_customer_success: false,
                open_api_access: false,
                zapier_api_access: false,
                email_retargeting: false,
                custom_analytics_landing_page: false,
                custom_fields_participants: false,
                statistics_access: "advanced",
                support: "extended",
                auth_methods: "all",
                added: "2021-03-25T17:11:59.821Z",
            },
        },
        expert: {
            products: {
                monthly: [
                    {
                        participant_count: 25,
                        prices: [
                            {
                                currency: "eur",
                                amount: 7900,
                                stripe_price_id: "price_1JVHKNBlUauxnBUzTReZX8M0",
                            },
                            {
                                currency: "usd",
                                amount: 9900,
                                stripe_price_id: "price_1JMYiMBlUauxnBUznzYMpe7v",
                            },
                        ],
                    },
                    {
                        participant_count: 50,
                        prices: [
                            {
                                currency: "eur",
                                amount: 7900,
                                stripe_price_id: "price_1JVHKNBlUauxnBUzTReZX8M0",
                            },
                            {
                                currency: "usd",
                                amount: 9900,
                                stripe_price_id: "price_1JMYiMBlUauxnBUznzYMpe7v",
                            },
                        ],
                    },
                    {
                        participant_count: 100,
                        prices: [
                            {
                                currency: "eur",
                                amount: 7900,
                                stripe_price_id: "price_1JVHKNBlUauxnBUzTReZX8M0",
                            },
                            {
                                currency: "usd",
                                amount: 9900,
                                stripe_price_id: "price_1JMYiMBlUauxnBUznzYMpe7v",
                            },
                        ],
                    },
                    {
                        participant_count: 150,
                        prices: [
                            {
                                currency: "eur",
                                amount: 8400,
                                stripe_price_id: "price_1JMYi3BlUauxnBUztAu6TcIG",
                            },
                            {
                                currency: "usd",
                                amount: 10500,
                                stripe_price_id: "price_1JMYi3BlUauxnBUzBbC4e5yP",
                            },
                        ],
                    },
                    {
                        participant_count: 200,
                        prices: [
                            {
                                currency: "eur",
                                amount: 9200,
                                stripe_price_id: "price_1JMYhgBlUauxnBUzo7b6looG",
                            },
                            {
                                currency: "usd",
                                amount: 11500,
                                stripe_price_id: "price_1JMYhgBlUauxnBUzgRra2PfM",
                            },
                        ],
                    },
                    {
                        participant_count: 250,
                        prices: [
                            {
                                currency: "usd",
                                amount: 12500,
                                stripe_price_id: "price_1JMYhMBlUauxnBUzqNpJ69hN",
                            },
                            {
                                currency: "eur",
                                amount: 10000,
                                stripe_price_id: "price_1JMYhMBlUauxnBUzzrX18tyJ",
                            },
                        ],
                    },
                    {
                        participant_count: 300,
                        prices: [
                            {
                                currency: "eur",
                                amount: 10800,
                                stripe_price_id: "price_1JMYglBlUauxnBUza7gXaCeU",
                            },
                            {
                                currency: "usd",
                                amount: 13500,
                                stripe_price_id: "price_1JMYglBlUauxnBUzrJdFtjNz",
                            },
                        ],
                    },
                ],
                yearly: [
                    {
                        participant_count: 300,
                        prices: [
                            {
                                currency: "usd",
                                amount: 90000,
                                stripe_price_id: "price_1JMYiMBlUauxnBUzvtecsNhb",
                            },
                            {
                                currency: "eur",
                                amount: 72000,
                                stripe_price_id: "price_1JMYiMBlUauxnBUzdCxwP1ri",
                            },
                        ],
                    },
                    {
                        participant_count: 600,
                        prices: [
                            {
                                currency: "usd",
                                amount: 90000,
                                stripe_price_id: "price_1JMYiMBlUauxnBUzvtecsNhb",
                            },
                            {
                                currency: "eur",
                                amount: 72000,
                                stripe_price_id: "price_1JMYiMBlUauxnBUzdCxwP1ri",
                            },
                        ],
                    },
                    {
                        participant_count: 1200,
                        prices: [
                            {
                                currency: "usd",
                                amount: 90000,
                                stripe_price_id: "price_1JMYiMBlUauxnBUzvtecsNhb",
                            },
                            {
                                currency: "eur",
                                amount: 72000,
                                stripe_price_id: "price_1JMYiMBlUauxnBUzdCxwP1ri",
                            },
                        ],
                    },
                    {
                        participant_count: 1800,
                        prices: [
                            {
                                currency: "usd",
                                amount: 102000,
                                stripe_price_id: "price_1JMYi3BlUauxnBUzthyJOCXi",
                            },
                            {
                                currency: "eur",
                                amount: 78000,
                                stripe_price_id: "price_1JMYi3BlUauxnBUzyVvJDLTv",
                            },
                        ],
                    },
                    {
                        participant_count: 2400,
                        prices: [
                            {
                                currency: "eur",
                                amount: 84000,
                                stripe_price_id: "price_1JMYhgBlUauxnBUzWcnyMZB2",
                            },
                            {
                                currency: "usd",
                                amount: 108000,
                                stripe_price_id: "price_1JMYhgBlUauxnBUz9frCOoIQ",
                            },
                        ],
                    },
                    {
                        participant_count: 3000,
                        prices: [
                            {
                                currency: "usd",
                                amount: 120000,
                                stripe_price_id: "price_1JMYhMBlUauxnBUzq3t8iHP5",
                            },
                            {
                                currency: "eur",
                                amount: 90000,
                                stripe_price_id: "price_1JMYhMBlUauxnBUzzbQuOxIb",
                            },
                        ],
                    },
                    {
                        participant_count: 3600,
                        prices: [
                            {
                                currency: "usd",
                                amount: 132000,
                                stripe_price_id: "price_1JMYglBlUauxnBUzGCumCJSi",
                            },
                            {
                                currency: "eur",
                                amount: 102000,
                                stripe_price_id: "price_1JMYglBlUauxnBUz2vINSbxF",
                            },
                        ],
                    },
                ],
            },
            features: {
                id: 18,
                unique_name: "Expert",
                public_name: "Expert",
                max_orga_slugs: 3,
                max_badge_campaigns: -1,
                whitelabel_email: true,
                whitelabel_badge: true,
                whitelabel_landing_page: true,
                online_certificates: true,
                credential_validation: true,
                custom_url_slugs: true,
                dedicated_customer_success: true,
                open_api_access: false,
                zapier_api_access: false,
                email_retargeting: false,
                custom_analytics_landing_page: false,
                custom_fields_participants: false,
                statistics_access: "advanced",
                support: "priority",
                auth_methods: "all",
                added: "2021-03-25T17:11:59Z",
            },
        },
    },
};
