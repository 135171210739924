import React from "react";
import { RouteProps } from "react-router-dom";
import { ALLOWED_HOSTS } from "./utils";

interface NoRouterProps extends RouteProps {
    // tslint:disable-next-line:no-any
    component: any;
    propsForComponent?: object;
    // All other props
    [x: string]: any;
}
/**
 * Component wrapper for when no Router is used
 *
 * @param param0
 * @returns
 */
export default function NoRouter(props: NoRouterProps) {
    if (!ALLOWED_HOSTS.includes(window.location.origin)) {
        return <></>;
    }

    const { component: Component, ...rest } = props;

    return <Component {...rest} />;
}
