import React, { useState } from "react";

// MUI
import Grid from "@material-ui/core/Grid";
import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import DescriptionIcon from "@material-ui/icons/Description";

// Misc
import Dropzone from "react-dropzone";
import jschardet from "jschardet";

const DEFAULT_BORDER = "dashed 4px grey";
const SUCCESS_BORDER = "solid 4px #4BB543";

type Props = {};

const UTF8Converter = (props: Props) => {
    const [originalFile, setOriginalFile] = useState<File | undefined>(undefined);
    const [downloadFile, setDownloadFile] = useState<File | Blob | undefined>(undefined);
    const [downloadFileURL, setDownloadFileURL] = useState<string>("");
    const [isParsing, setIsParsing] = useState<boolean>(false);
    const [encoding, setEncoding] = useState<string>("");

    const onFileUpload = async (files: File[]) => {
        setIsParsing(true);
        let ogFile = files[0];
        console.log(ogFile);
        setOriginalFile(ogFile);

        let detectEncodingReader = new FileReader();
        let _encoding = "utf-8";
        await new Promise((resolve) => {
            detectEncodingReader.onload = () => {
                let buffer = detectEncodingReader.result as string;
                let detectedBuffer = jschardet.detect(buffer);
                _encoding = detectedBuffer.encoding;
                setEncoding(detectedBuffer.encoding);

                resolve(true);
            };
            detectEncodingReader.readAsBinaryString(ogFile);
        });

        let fileReader = new FileReader();
        fileReader.onload = () => {
            let buffer = fileReader.result as string;

            let blob = new Blob([buffer], { type: "text/csv;charset=utf-8;" });
            let blobURL = URL.createObjectURL(blob);
            setDownloadFile(blob);
            setDownloadFileURL(blobURL);

            // Artificial loading
            setTimeout(() => {
                setIsParsing(false);
            }, 1000);
        };
        console.log("reading with encoding", _encoding);
        fileReader.readAsText(ogFile, _encoding);
    };

    const download = () => {
        if (!downloadFile) return;

        var link = document.createElement("a");
        link.setAttribute("href", downloadFileURL);
        link.setAttribute("download", `encoded-${originalFile?.name}`);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const parsingDone = downloadFile && !isParsing;

    return (
        <div>
            <Grid container direction="row" justify="space-between">
                <Grid item>
                    <Grid
                        container
                        direction="column"
                        alignItems="center"
                        justify="center"
                        style={{
                            width: 300,
                            height: 300,
                            border: DEFAULT_BORDER,
                            borderRadius: 20,
                            backgroundColor: "white",
                        }}
                    >
                        <Dropzone onDrop={(acceptedFiles) => onFileUpload(acceptedFiles)} maxFiles={1}>
                            {({ getRootProps, getInputProps }) => (
                                <section>
                                    <div
                                        {...getRootProps()}
                                        className="d-flex justify-content-center align-items-center flex-column m-5"
                                    >
                                        <input {...getInputProps()} accept=".csv" />
                                        <span style={{ fontSize: "5rem" }}>
                                            <SystemUpdateAltIcon
                                                fontSize="inherit"
                                                style={{ transform: "rotate(180deg)" }}
                                            />
                                        </span>
                                        {originalFile ? (
                                            <p className="text-center" style={{ width: 150, wordWrap: "break-word" }}>
                                                {originalFile.name}
                                                <br />
                                                <small className="text-muted">{encoding ? `(${encoding})` : ""}</small>
                                            </p>
                                        ) : (
                                            <p className="text-center">
                                                Drag your CSV file here or click to browse for it
                                            </p>
                                        )}
                                    </div>
                                </section>
                            )}
                        </Dropzone>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container direction="column" alignItems="center" justify="center" style={{ height: 300 }}>
                        <span style={{ fontSize: "5rem" }}>
                            <ArrowRightAltIcon fontSize="inherit" />
                        </span>
                        {isParsing && <p className="text-muted">parsing to UTF-8...</p>}
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid
                        container
                        direction="column"
                        alignItems="center"
                        justify="center"
                        style={{
                            width: 300,
                            height: 300,
                            border: parsingDone ? SUCCESS_BORDER : DEFAULT_BORDER,
                            borderRadius: 20,
                            backgroundColor: "white",
                        }}
                    >
                        <div
                            onClick={download}
                            className="d-flex justify-content-center align-items-center flex-column"
                            style={{ cursor: "pointer" }}
                        >
                            <span style={{ fontSize: "5rem" }}>
                                <DescriptionIcon fontSize="inherit" />
                            </span>
                            {parsingDone ? (
                                <p className="text-center" style={{ width: 150, wordWrap: "break-word" }}>
                                    {`encoded-${originalFile?.name}`}
                                    <br />
                                    <small className="text-muted">(UTF-8)</small>
                                </p>
                            ) : (
                                <p>Download is not yet available</p>
                            )}
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default UTF8Converter;
