import { CREATE_MESSAGE, CLEAR_MESSAGES } from "./types";

// CREATE MESSAGE
export const createMessage = (msg: msg, severity: severity, duration: duration = 5000) => {
    console.log(msg);
    return {
        type: CREATE_MESSAGE,
        payload: { msg, severity, timestamp: Date.now(), duration },
    };
};

// CLEAR MESSAGES
export const clearMessages = () => {
    return {
        type: CLEAR_MESSAGES,
        payload: null,
    };
};
