import React, { useState } from "react";

// Utils
import { useBrand } from "../../../utils/customHooks";
import { slicePriceId } from "../../Common/PlanSelection/utils";
import { sanityCheckField } from "../../../utils/utils";
import { renderCurrency, getRoundedMonthlyPrice } from "../Pricing/utils/library";
import { smallParagraph, getBoxPadding, containerBorderStyles } from "./utils/styles";

// Redux
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { register } from "../../../actions/register";
import { createMessage } from "../../../actions/messages";

// mui styles
import theme from "../../theme";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

// mui components
import MuiPhoneNumber from "material-ui-phone-number";
import Tooltip from "@material-ui/core/Tooltip";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";

// mui icons
import Visibility from "@material-ui/icons/Visibility";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import IconButton from "@material-ui/core/IconButton";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

// localization
import { useTranslation, Trans } from "react-i18next";
import i18n from "i18next";

const checkboxLabel: React.CSSProperties = {
    color: theme.palette.grey[600],
    fontSize: "14px",
    marginTop: "11px",
    paddingLeft: "4px",
    fontWeight: 400,
};

const useStylesRegisterBtn = makeStyles({
    root: {
        width: 204,
        height: 42,
        background: theme.palette.vbYellow.main,
        color: theme.palette.grey[900],
        fontWeight: 600,
        textTransform: "none",
        boxShadow: "none",
        padding: "6px 12px",
        "&:hover": {
            background: theme.palette.vbYellow.darker,
            boxShadow: "none",
        },
    },
    label: {
        fontSize: "100%",
    },
});

const ColoredCheckbox = withStyles({
    root: {
        color: theme.palette.grey[400],
        "&$checked": {
            color: theme.palette.primary.dark,
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
    customTooltip: {
        backgroundColor: theme.palette.grey[100],
        color: theme.palette.grey[600],
        maxWidth: 1000,
        border: "solid 1px lightgrey",
        marginBottom: 10,
    },
    customArrow: {
        color: theme.palette.grey[100],
        "&::before": {
            border: "solid 1px lightgrey",
        },
    },
}));

export default function Register() {
    const { t } = useTranslation();
    // redux
    const dispatch = useAppDispatch();
    const { pricing, isRegisterLoading } = useAppSelector((state) => ({
        pricing: state.pricing.pricing,
        isRegisterLoading: state.loading.isRegisterLoading,
    }));

    // state hooks
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [industry, setIndustry] = useState("");
    const [checkedTermsPrivacy, setCheckedTermsPrivacy] = useState<boolean>(false);

    // Errors
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [phoneNumberError, setPhoneNumberError] = useState(false);
    const [companyNameError, setCompanyNameError] = useState(false);
    const [checkedTermsPrivacyError, setCheckedTermsPrivacyError] = useState(false);

    // Measure time to form submit from form mount
    const [timestampFormMounted] = useState(Date.now());
    const [registerButtonDisabled, setregisterButtonDisabled] = useState(false);
    const [openEmailTooltip, setOpenEmailTooltip] = useState(false);

    const [showPassword, setShowPassword] = useState(false);

    // Misc hooks
    const classes = useStyles();
    const classesRegisterBtn = useStylesRegisterBtn();

    const brandManager = useBrand();

    // layout hooks
    const size__md_down = useMediaQuery(theme.breakpoints.down("md"));
    const size__sm_down = useMediaQuery(theme.breakpoints.down("sm"));

    /**
     * onChange event handler for three different input fields: email, password, privacy checked
     * @param event
     */
    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        switch (event.target.name) {
            case "email":
                let _email = event.target.value;

                if (_email.includes("@")) {
                    let domain = _email.split("@")[1];
                    if (
                        domain.includes("gmail") ||
                        domain.includes("gmx") ||
                        domain.includes("web") ||
                        domain.includes("yahoo") ||
                        domain.includes("hotmail") ||
                        domain.includes("icloud") ||
                        domain.includes("mail")
                    )
                        setOpenEmailTooltip(true);
                    else setOpenEmailTooltip(false);
                }

                setEmail(event.target.value);
                break;
            case "password":
                setPassword(event.target.value);
                break;
            case "checkedTermsPrivacy":
                setCheckedTermsPrivacy(event.target.checked);
                break;
            default:
                break;
        }
    };

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        // First name
        if (!firstName) {
            dispatch(createMessage(t("register.messages.message1", "Please fill in your first name"), "info"));
            setFirstNameError(true);
            return;
        }
        // Last name
        if (!lastName) {
            dispatch(createMessage(t("register.messages.message2", "Please fill in your last name"), "info"));
            setLastNameError(true);
            return;
        }
        // Phone number
        if (!phoneNumber || phoneNumber.length < 12) {
            dispatch(createMessage(t("register.messages.message3", "Please fill in a valid phone number"), "info"));
            setPhoneNumberError(true);
            return;
        }
        // Orga name
        if (!companyName) {
            dispatch(createMessage(t("register.messages.message4", "Please fill in your organisation's name"), "info"));
            setCompanyNameError(true);
            return;
        }
        // Orga name - validity check
        if (!sanityCheckField("name", companyName)) {
            dispatch(
                createMessage(t("register.messages.message5", "Please enter a name with at least 3 characters"), "info")
            );
            setCompanyNameError(true);
            return;
        }

        // Email
        if (!sanityCheckField("email", email, true)) {
            dispatch(
                createMessage(t("register.messages.message6", "Please enter a valid email address."), "info", 8000)
            );
            setEmailError(true);
            return;
        }

        // Password
        if (!password || !sanityCheckField("password", password)) {
            dispatch(
                createMessage(
                    t(
                        "register.messages.message7",
                        "Your password must be at least 8 characters long and contain one uppercase letter, one lowercase letter and one numeric character"
                    ),
                    "info",
                    8000
                )
            );
            setPasswordError(true);
            return;
        }

        // Terms
        if (!checkedTermsPrivacy) {
            dispatch(
                createMessage(
                    t(
                        "register.messages.message8",
                        "Please agree to the Terms of Use and the Privacy Policy of this service."
                    ),
                    "info",
                    8000
                )
            );
            setCheckedTermsPrivacyError(true);
            return;
        }

        const user = {
            email,
            password,
            first_name: firstName,
            last_name: lastName,
            phone_number: phoneNumber,
        };

        const orga = {
            name: companyName,
            industry: industry,
        };

        setregisterButtonDisabled(true);

        let callback = () => {
            // Callback
            setregisterButtonDisabled(false);
        };
        let successCallback = () => {
            // Success callback
            setFirstName("");
            setLastName("");
            setPhoneNumber("");
            setCompanyName("");
            setIndustry("");
            setEmail("");
            setPassword("");
            setEmail("");
            setPassword("");
        };

        const signupDuration = Date.now() - timestampFormMounted;
        console.log("Register signupDuration", signupDuration);

        dispatch(
            // @ts-ignore
            register({ user, orga, duration: signupDuration }, brandManager.getAccountType(), callback, successCallback)
        );
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    let defaultCountry = "us";
    if (i18n.language) defaultCountry = i18n.language === "de" ? "de" : "us";

    return (
        <div
            style={{
                width: size__sm_down ? "100%" : "98%",
                marginTop: size__sm_down ? 24 : 0,
            }}
        >
            {/* Register Form */}
            <form onSubmit={onSubmit}>
                <p style={smallParagraph}>
                    {t("register.alreadyHaveAnAccount", "Already have an account?")}{" "}
                    <a href={`${brandManager.getAdminPanelUrl()}/login`} target="_blank">
                        {t("register.loginHere", "Login here")}
                    </a>
                </p>
                {/* User's name */}
                <div>
                    <Grid container justify="space-between" spacing={2}>
                        <Grid item lg={6} md={12} sm={6} xs={12}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                name="first_name"
                                autoComplete="given-name"
                                size="small"
                                onChange={(event) => setFirstName(event.target.value)}
                                value={firstName}
                                label={t("register.form.firstName", "First Name")}
                                required
                                error={firstNameError}
                                onFocus={() => setFirstNameError(false)}
                            />
                        </Grid>
                        <Grid item lg={6} md={12} sm={6} xs={12}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                name="last_name"
                                autoComplete="family-name"
                                size="small"
                                onChange={(event) => setLastName(event.target.value)}
                                value={lastName}
                                label={t("register.form.lastName", "Last Name")}
                                required
                                error={lastNameError}
                                onFocus={() => setLastNameError(false)}
                            />
                        </Grid>
                    </Grid>
                    {/* Phone number */}
                    <div className="mt-3">
                        <MuiPhoneNumber
                            variant="outlined"
                            size="small"
                            label={t("register.form.phoneNumber", "Phone Number")}
                            name="phone_number"
                            fullWidth
                            value={phoneNumber}
                            onChange={(_phoneNumber) => setPhoneNumber(_phoneNumber)}
                            required
                            error={phoneNumberError}
                            onFocus={() => setPhoneNumberError(false)}
                            defaultCountry={defaultCountry}
                            disableAreaCodes
                            countryCodeEditable={false}
                        />
                    </div>
                    {/* Orga name */}
                    <div className="mt-3" />
                    <TextField
                        variant="outlined"
                        fullWidth
                        name="company_name"
                        size="small"
                        onChange={(event) => setCompanyName(event.target.value)}
                        value={companyName}
                        label={t("register.form.orgaName", "Organisation Name")}
                        required
                        error={companyNameError}
                        onFocus={() => setCompanyNameError(false)}
                    />
                    {/* Orga segment */}
                    <div className="mt-3" />
                    <div className="d-none">
                        <TextField
                            id="register-orga-segment"
                            fullWidth
                            label={t("register.form.industry", "Industry")}
                            size="small"
                            name="industry"
                            variant="outlined"
                            onChange={(event) => setIndustry(event.target.value)}
                            value={industry}
                            SelectProps={{
                                native: true,
                            }}
                        />
                    </div>
                </div>

                {/* Email */}
                <div className="form-group mt-3">
                    <div>
                        <Tooltip
                            title={
                                <span style={{ fontSize: "0.9rem", display: "flex", alignItems: "center" }}>
                                    <WarningRoundedIcon
                                        style={{ fontSize: "1.4rem", marginRight: 5, color: "#FFD700" }}
                                    />
                                    {t("register.pleaseUseYourCompanyEmail", "Please use your work email address")}
                                </span>
                            }
                            arrow
                            open={openEmailTooltip}
                            classes={{
                                tooltip: classes.customTooltip,
                                arrow: classes.customArrow,
                            }}
                            placement="top"
                        >
                            <div>
                                <TextField
                                    name="email"
                                    variant="outlined"
                                    label={t("register.form.email", "Email")}
                                    size="small"
                                    fullWidth
                                    type="email"
                                    autoComplete="email"
                                    value={email}
                                    onChange={onChange}
                                    error={emailError}
                                    onFocus={() => setEmailError(false)}
                                    required
                                />
                            </div>
                        </Tooltip>
                    </div>
                </div>

                {/* Password */}
                <div className="form-group">
                    <TextField
                        type={showPassword ? "text" : "password"}
                        name="password"
                        label={t("register.form.password", "Password")}
                        variant="outlined"
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                        error={passwordError}
                        onFocus={() => setPasswordError(false)}
                        size="small"
                        fullWidth
                        autoComplete="current-password"
                        required
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={(event) => event.preventDefault()}
                                        edge="end"
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    {/* password requirements */}
                    <p style={{ marginTop: 4, fontSize: 12, color: theme.palette.grey[600] }}>
                        <em>
                            {t(
                                "register.passwordRequirements",
                                "Your password must be at least 8 characters long and contain one uppercase letter, one lowercase letter and one numeric character"
                            )}
                        </em>
                    </p>
                </div>
                {/* checkbox terms */}
                <div className="d-flex mt-4" style={{ marginLeft: -12 }}>
                    <ColoredCheckbox
                        // @ts-ignore
                        id="checkedTermsPrivacy"
                        checked={checkedTermsPrivacy}
                        name="checkedTermsPrivacy"
                        onChange={onChange}
                        color="primary"
                        inputProps={{ "aria-label": "primary checkbox" }}
                    />
                    <label style={checkboxLabel} htmlFor="checkedTermsPrivacy">
                        <Trans t={t} i18nKey="register.consent">
                            By signing up, I agree with the <a href="https://www.virtualbadge.io/terms">Terms of Use</a>
                            and <a href="https://www.virtualbadge.io/privacy-policy">Privacy Policy</a> of
                            Virtualbadge.io.
                        </Trans>
                    </label>
                </div>
                <br />
                {/* Signup Button  */}
                <div className="d-flex justify-content-start">
                    <div className="form-group d-flex align-items-start flex-column mb-2">
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={registerButtonDisabled || isRegisterLoading}
                            classes={{
                                root: classesRegisterBtn.root,
                                label: classesRegisterBtn.label,
                            }}
                        >
                            {t("register.signupButton", "Sign up")}
                        </Button>
                    </div>
                </div>
            </form>
        </div>
    );
}
