import React from "react";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
// Redux
import { Provider } from "react-redux";
import store from "../redux/store";

// components
import PublicRoute from "./Common/Routing/PublicRoute";
import Navigator from "./Common/Navigator";
import Alerts from "./Common/Alerts";
import PlanSelectionWrapper from "./Apps/Pricing/PlanSelectionWrapper";
import CertificateValidator from "./Apps/CertificateValidator/CertificateValidator";
import LinkedinCertificateLinkGenerator from "./Apps/LinkedinCertificateLinkGenerator/LinkedinCertificateLinkGenerator";
import UTF8Converter from "./Apps/UTF8Converter/UTF8Converter";
import Register from "./Apps/Register/Register";
import NotFound from "./Common/NotFound";
import Test from "./Common/Test";
import Helmet from "./Common/Helmet";
// websummit
// import EmailForm from "./Apps/WebSummit/EmailForm";
// import CertificateWall from "./Apps/WebSummit/CertificateWall";

// utils
import { ROUTES } from "../utils/routes";

function App() {
    return (
        <Provider store={store}>
            <Router>
                <div className="pc-app">
                    <Alerts />
                    <Helmet />
                    <Switch>
                        {/* navigator is the root path. Only allowed in local */}
                        <PublicRoute exact path={ROUTES.navigator} component={Navigator} />
                        <PublicRoute
                            exact
                            path={ROUTES.linkedinCertificateLinkGenerator}
                            component={() => <LinkedinCertificateLinkGenerator privateRoute={false} />}
                        />
                        <PublicRoute
                            exact
                            path={ROUTES.linkedinCertificateLinkGeneratorDe}
                            component={() => <LinkedinCertificateLinkGenerator privateRoute={false} />}
                        />
                        <PublicRoute path={ROUTES.pricing} component={PlanSelectionWrapper} />
                        <PublicRoute exact path={ROUTES.certificateValidator} component={CertificateValidator} />
                        <PublicRoute exact path={ROUTES.utf8converter} component={UTF8Converter} />
                        <PublicRoute exact path={"/test"} component={Test} />
                        <PublicRoute exact path={ROUTES.register} component={Register} />

                        {/* websummit */}
                        {/* <PublicRoute exact path={ROUTES.websummit.email} component={EmailForm} />
                        <PublicRoute exact path={ROUTES.websummit.wall} component={CertificateWall} /> */}

                        <PublicRoute exact path={ROUTES.notfound} component={NotFound} />
                        <Redirect to={ROUTES.notfound} />
                    </Switch>
                </div>
            </Router>
        </Provider>
    );
}

export default App;
