import React, { useState } from "react";

// Misc
import Dropzone from "react-dropzone";
import axios from "axios";
import { useTranslation } from "react-i18next";

// Redux
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { createMessage } from "../../../actions/messages";
import { setPdfValidity } from "../../../actions/certificate_validator";

// Mui
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import UploadFileIcon from "../../../Assets/Images/Apps/CertificateValidator/upload-icon.svg";
import VerifiedIcon from "../../../Assets/Images/Apps/CertificateValidator/pdf-verified-icon.svg";

type Props = {
    signingCertificateName: string;
    environment: string;
};

const getVerifyPdfFunctionUrl = (environment: string) => {
    switch (environment) {
        // Uncomment this line to test the function running locally
        // return "http://localhost:7071/api/verifyPdf";
        case "local":
        case "development":
            return "https://virtualbadge-functions-node-dev.azurewebsites.net/api/verifyPdf";
        case "staging":
            return "https://virtualbadge-functions-node-stage.azurewebsites.net/api/verifyPdf";
        case "prod":
        default:
            return "https://virtualbadge-functions-node.azurewebsites.net/api/verifyPdf";
    }
};

const UploadPDF = ({ signingCertificateName, environment }: Props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { validity } = useAppSelector((state) => ({
        validity: state.certificate_validator.pdfValidity,
    }));

    const [loading, setLoading] = useState(false);

    /**
     * Uploads the pdf file to the backend and checks if it is valid
     * @param files
     */
    const onFileUpload = async (files: File[]) => {
        setLoading(true);
        const pdf = files[0];

        // Create form data
        const formData = new FormData();
        formData.append("pdf_file", pdf);
        formData.append("cert_name", signingCertificateName);

        axios({
            method: "post",
            url: getVerifyPdfFunctionUrl(environment),
            data: formData,
        })
            .then((response) => {
                console.log(response);
                if (response.data.verified) {
                    dispatch(setPdfValidity("valid"));
                } else dispatch(setPdfValidity("invalid"));
            })
            .catch((err) => {
                console.log(err.response.status);
                dispatch(setPdfValidity("invalid"));
            })
            .finally(() => {
                setLoading(false);
            });
    };

    /**
     * Returns the background color of the box depending on the validity
     * @returns
     */
    const getBackgroundColor = () => {
        switch (validity) {
            case "valid":
                return "#E0FFE3";
            case "invalid":
                return "#FFEEEE";
            case "not_checked":
            default:
                return "#F5F5F5";
        }
    };

    // If no key_name is configured, this means there is not pdf validation
    if (!signingCertificateName) return null;

    return (
        <Box
            style={{
                borderRadius: 8,
                backgroundColor: getBackgroundColor(),
                padding: "20px 40px 20px 40px",
                textAlign: "center",
                width: "100%",
                height: "100%",
                minHeight: validity === "valid" ? 130 : 180,
            }}
        >
            {loading ? (
                <Box>
                    <Typography variant="body1" style={{ fontWeight: 600 }}>
                        {t("certificate_validator.upload_pdf.checking_validity", "Checking Validity...")}
                    </Typography>
                    <br />
                    <CircularProgress />
                </Box>
            ) : (
                <Dropzone onDrop={(acceptedFiles) => onFileUpload(acceptedFiles)} maxFiles={1}>
                    {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()}>
                            {validity === "not_checked" && (
                                <div>
                                    <Typography variant="body1" style={{ fontWeight: 600 }}>
                                        {t(
                                            "certificate_validator.upload_pdf.verify_certificate",
                                            "Validate PDF Certificate"
                                        )}
                                    </Typography>
                                    <Typography variant="body2">
                                        {t(
                                            "certificate_validator.upload_pdf.upload_pdf",
                                            "Upload the PDF version of this certificate to validate its authenticity."
                                        )}
                                    </Typography>
                                    <img src={UploadFileIcon} alt="Upload PDF icon" />
                                </div>
                            )}
                            {validity === "valid" && (
                                <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <img src={VerifiedIcon} alt="PDF Verified icon" />
                                    <Box style={{ width: "80%" }}>
                                        <Typography variant="body1" style={{ fontWeight: 600 }}>
                                            {t(
                                                "certificate_validator.upload_pdf.successfully_verified",
                                                "PDF certificate was successfully validated"
                                            )}
                                        </Typography>
                                        <Typography variant="body2">
                                            {t(
                                                "certificate_validator.upload_pdf.matches_digital_version",
                                                "The PDF version uploaded matches the digital signature of this certificate."
                                            )}
                                        </Typography>
                                    </Box>
                                </Box>
                            )}
                            {validity === "invalid" && (
                                <div>
                                    <Typography variant="body1" style={{ fontWeight: 600 }}>
                                        {t(
                                            "certificate_validator.upload_pdf.certificate_not_valid",
                                            "PDF certificate is not valid"
                                        )}
                                    </Typography>
                                    <Typography variant="body2">
                                        {t(
                                            "certificate_validator.upload_pdf.does_not_matches_digital_version",
                                            "The PDF version uploaded does not match the digital signature of this certificate. Upload new version to validate again."
                                        )}
                                    </Typography>
                                    <img src={UploadFileIcon} alt="Upload PDF icon" />
                                </div>
                            )}
                            <input {...getInputProps()} accept=".pdf" />
                        </div>
                    )}
                </Dropzone>
            )}
        </Box>
    );
};

export default UploadPDF;
