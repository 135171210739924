const _brands = {
    virtualbadge: {
        brandName: "Virtualbadge.io",
        brandNameWithTld: "virtualbadge.io",
        pageTitle: "Virtualbadge.io Public Pages",
        url: "https://www.virtualbadge.io",
        htmlHead: {
            faviconName: "favicon-vb.png",
        },
        brandColors: {
            primary: "#223D71",
            secondary: "#FFD200",
        },
    },
    schulungszertifikate: {
        brandName: "Schulungszertifikate.de",
        brandNameWithTld: "schulungszertifikate.de",
        pageTitle: "Schulungszertifikate.de Öffentliche Seiten",
        url: "https://www.schulungszertifikate.de",
        htmlHead: {
            faviconName: "favicon-sz.png",
        },
        brandColors: {
            primary: "#223D71",
            secondary: "#FFD200",
        },
    },
};
const BRAND_VALUES = {
    ..._brands,
    saasmantra: _brands.virtualbadge,
};

class BrandManager {
    account_type: TAccountType;

    constructor(account_type: TAccountType) {
        this.account_type = account_type;
    }

    getBrandFromAccountType = (): TAccountType => {
        switch (this.account_type) {
            case "virtualbadge":
                return "virtualbadge";
            case "schulungszertifikate":
                return "schulungszertifikate";
        }
    };

    getAccountType = (): TAccountType => this.account_type;
    getBrandName = (): string => BRAND_VALUES[this.account_type].brandName;
    getBrandUrl = (): string => BRAND_VALUES[this.account_type].url;
    getPageTitle = (): string => BRAND_VALUES[this.account_type].pageTitle;
    getHtmlHead = () => BRAND_VALUES[this.account_type].htmlHead;
    getBrandColor = () => BRAND_VALUES[this.account_type].brandColors;
    getBrandNameWithTld = (): string => BRAND_VALUES[this.account_type].brandNameWithTld;

    getAdminPanelUrl = () => {
        switch (process.env.NODE_ENV) {
            case "development":
                return "http://127.0.0.1:3000";
            case "production":
                if (process.env.REACT_APP_ENV === "development") return `https://dev.${this.getBrandNameWithTld()}`;
                else return `https://admin.${this.getBrandNameWithTld()}`;
            default:
                return `https://admin.${this.getBrandNameWithTld()}`;
        }
    };
}

export default BrandManager;

export const getBrandFromLocation = (hostname: string): TAccountType => {
    // console.log("hostname", hostname);
    const vb_hosts = ["public.virtualbadge.io", "dev.public.virtualbadge.io"];
    const sz_hosts = ["public.schulungszertifikate.de", "dev.public.schulungszertifikate.de"];

    const urlParams = new URLSearchParams(window.location.search);
    const brandFromUrlParam = urlParams.get("brand");

    if (brandFromUrlParam) return brandFromUrlParam as TAccountType;
    else if (vb_hosts.includes(hostname)) return "virtualbadge" as TAccountType;
    else if (sz_hosts.includes(hostname)) return "schulungszertifikate" as TAccountType;
    else return "virtualbadge";
    // else return "schulungszertifikate";
};
