import { format, parseISO } from "date-fns";
import { de as deLocale, enUS as enLocale } from "date-fns/locale";

// ##--------------------------------------##
// #            Validation stuff            #
// ##--------------------------------------##

/**
 * Email validation
 *
 * @param email
 * @returns boolean, if the string is an email
 */
export function validateEmail(email: string): boolean {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export function getUrlParams(url?: string): URLSearchParams {
    // parameter url is passed when the calling component was mounted within an iframe. Then url holds the parents url
    // Get all parameters from the url
    let search = url || window.location.search;
    let params = new URLSearchParams(search);
    return params;
}

/* Get maximum index of element in object array
 * e.g. arr = [{x: 1, y:1}, {x:2, y:2}]
 * getMaximumInObjectArray(arr, "y") will return 1
 */
export function getMaximumInObjectArray(array: number[], element: string) {
    let index: number;
    let max: number;
    index = 0;
    max = 0;
    array.forEach((a: any, i: number) => {
        if (max === 0) {
            max = a[element];
            index = i;
        } else {
            if (a[element] > max) {
                max = a[element];
                index = i;
            }
        }
    });
    return index;
}

/**
 * From a date string, as provided by the backend", get a nicer way of displaying
 *
 * @param expiration_date
 * @returns
 */
export const getValidityForPreview = (expiration_date: string, locale: "en" | "de"): string => {
    return format(parseISO(expiration_date), "d LLLL y", { locale: locale === "en" ? enLocale : deLocale });
};

/**
 * Determines whether the certificate datestamp is in the past. Returns true if it is expired, false otherwise
 *
 * @param expiration_date
 * @returns
 */
export const getCertificateExpired = (expiration_date: string | undefined): boolean => {
    if (!expiration_date) return false;
    return parseISO(expiration_date) < new Date();
};

/**
 * A function to check for validity of fields.
 * @param   {string}  field_name              Can be the name attribute of the TextField input, or the respective identifier
 * @param   {string}  field_value             The actual value
 * @param   {boolean} [get_occurence=false]   If true returns the first value found. Otherwise (default) returns Bool
 * @returns {(string | boolean | undefined)}  Returns true if found (for occurence=false), the occurence string or undefined (for occurence=true)
 */
export function sanityCheckField(
    field_name: string,
    field_value: string,
    get_occurence: boolean = false
): string | boolean | undefined {
    switch (field_name) {
        case "name":
            // min. 3 characters
            return field_value.length >= 3 ? true : false;
        case "url": {
            // valid characters, at least one ".", two or three characters
            let re = /[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,3}(\/\S*)?/;
            let hits = re.exec(field_value);
            console.log("hits", hits);
            if (get_occurence) return hits ? hits[0] : undefined;
            else return hits ? true : false;
        }
        case "color": {
            // a valid hex code: optional "#", has 3, 6 or 8 chars or numbers
            let re = /^#?([a-zA-Z0-9]{3}|[a-zA-Z0-9]{6}|[a-zA-Z0-9]{8})$/;
            return re.exec(field_value) ? true : false;
        }
        case "email": {
            // this is a quite generic regex, as the sophisticated expressions are expensive,
            //    and the only true way to validate email addresses is by actually sending an email
            let re = /\S+@\S+\.\S+/;
            let hits = re.exec(field_value);
            if (get_occurence) return hits ? hits[0] : undefined;
            else return hits ? true : false;
        }
        case "password": {
            /* 
            This regex will enforce these rules:
                At least one upper case English letter, (?=.*?[A-Z])
                At least one lower case English letter, (?=.*?[a-z])
                At least one digit, (?=.*?[0-9])
                Minimum eight in length .{8,} (with the anchors)
            */
            let re = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/;
            let hits = re.exec(field_value);
            console.log("get_occurence", get_occurence);
            console.log(hits);
            if (get_occurence) {
                let hit = hits ? hits[0] : undefined;
                return hit === field_value ? hit : undefined;
            } else return hits ? true : false;
        }
        default:
            return false;
    }
}

// ##--------------------------------------##
// #             Cookie stuff               #
// ##--------------------------------------##
export function getCookie(name: string): string | void {
    const value = `; ${document.cookie}`;
    const parts: string[] | undefined = value.split(`; ${name}=`);
    if (parts?.length === 2) return parts?.pop()?.split(";").shift();
    else return;
}

/**
 * This function capitalizes the first char of a string: capitalizeFirstLetter('foo bar'); // Foo bar
 * @param string
 * @returns
 */
export function capitalizeFirstLetter(string: string) {
    return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
}
// This function downloads PNG File by first converting Img link (certificationPicture: string) into Base64, creating a canvas to manipulate the img file and then downloading it onClick. Works in I-frame
export async function handleImgDownload(sourceUrl: string, outputFormat = "image/png") {
    const getBase64Image = async () => {
        const img = new Image();
        img.crossOrigin = "Anonymous";
        img.src = sourceUrl;
        return await new Promise((resolve) => {
            img.onload = () => {
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");
                canvas.height = img.naturalHeight;
                canvas.width = img.naturalWidth;
                ctx?.drawImage(img, 0, 0);
                const dataURL = canvas.toDataURL(outputFormat);
                console.log(dataURL);
                resolve(canvas.toDataURL(outputFormat));
            };
        });
    };
    const anchor = Object.assign(document.createElement("a"), {
        href: await getBase64Image(),
        style: "display:none",
        download: "myBadge.png",
        target: "_blank",
    });
    document.body.appendChild(anchor);
    anchor.click();
    anchor.remove();
}

/**
 * Helper function to check if a string has a length between min and max
 * @param val
 * @param min
 * @param max
 * @returns boolean
 */
export const hasStringLengthBetween = (val: string, min: number, max: number): boolean => {
    return val.length >= min && val.length <= max;
};
