import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { cleanUp } from "../utils/cleanStringIntoHTML";
import { useWindowSize } from "../../../../utils/customHooks";

// Import images
import facebookLogo from "./../../../../Assets/Images/Apps/CertificateValidator/SocialMediaBrands/f_logo_RGB-Blue_58.png";
import linkedinLogo from "./../../../../Assets/Images/Apps/CertificateValidator/SocialMediaBrands/In-2CRev-128px-TM.png";
import instagramLogo from "./../../../../Assets/Images/Apps/CertificateValidator/SocialMediaBrands/Instagram_Glyph_Gradient_RGB.png";
import mailLogo from "./../../../../Assets/Images/Apps/CertificateValidator/SocialMediaBrands/mail_icon_400_400.png";
import xLogo from "./../../../../Assets/Images/Apps/CertificateValidator/SocialMediaBrands/X.png";
import whatsappLogo from "./../../../../Assets/Images/Apps/CertificateValidator/SocialMediaBrands/whatsapp_logo.png";
import xingLogo from "./../../../../Assets/Images/Apps/CertificateValidator/SocialMediaBrands/XNG_Sharebutton.png";
import { Tooltip } from "@material-ui/core";
import certificationValidatorSizeHandle from "../utils/styles";
import InstagramPopUp from "../InstagramPopUp";

interface Props {
    sharedURL: string;
    certificateName: string;
    certificationPicture: string;
}

interface SocialMediaLinkInfo {
    socialMediaBrandName: string;
    socialMediaLogo: string;
    shareHref: string;
    havePopUp?: boolean;
}

export default function ShareButton(props: Props): ReactElement {
    const [windowWidth] = useWindowSize();
    // translator hooks
    const { t } = useTranslation();

    const [openPopUp, setOpenPopUp] = useState<boolean>(false);

    //Cleaning URL and certification event name from problematic parts without changing them in viewpoint
    const cleanedUrl = cleanUp(props.sharedURL);
    const cleanedCertificationName = cleanUp(props.certificateName);

    //Array with important informations for each single button (SocialMedia-Name, Logo as button image, !!! Sharelink !!)
    /* To add new Social Media Brand use:
    {
        socialMediaBrandName: "",
        socialMediaLogo: "",    // use "doImgPath(>file name from logo inc .*<)" to set the correct src for the img-Tag
                                // use prio .svg files or sec .png
        shareHref: "",          // cleanedUrl ->URL where iframe is exp. https://www.customershaveweb.com/certificate?credential=dsa_2213213_242422233_kksdfjlsdf
                                // cleanedCertificationName ->Event of certification
                                // use + instead of , to seperate strings from variables
                                // props.certificationPicture ->For the picturepath of the certification
    },
-------------------------------------------------------------------------------
!! Dont use a sharing-text or the CertificationName and the picture
!! Till posible reales with "Changable Meta-Tags".
-------------------------------------------------------------------------------
    */
    const socialMediaLinkArray: SocialMediaLinkInfo[] = [
        {
            socialMediaBrandName: "LinkedIn",
            socialMediaLogo: linkedinLogo,
            shareHref:
                "http://www.linkedin.com/sharing/share-offsite/?mini=true&url=" +
                cleanedUrl +
                "&title=" +
                t("certificate_validator.shareButton.i_get_my_certification_for") +
                "%22" +
                cleanedCertificationName +
                "%22&source=" +
                cleanedUrl +
                "&submitted-image-url=" +
                props.certificationPicture,
        },
        {
            socialMediaBrandName: "Xing",
            socialMediaLogo: xingLogo,
            shareHref: "https://www.xing.com/spi/shares/new?url=" + cleanedUrl,
        },
        {
            socialMediaBrandName: "Instagram",
            socialMediaLogo: instagramLogo,
            shareHref: "https://www.instagram.com/?url=" + cleanedUrl,
            havePopUp: true,
        },
        {
            socialMediaBrandName: "WhatsApp",
            socialMediaLogo: whatsappLogo,
            shareHref: "whatsapp://send?text=" + cleanedUrl,
        },
        {
            socialMediaBrandName: "Facebook",
            socialMediaLogo: facebookLogo,
            shareHref: "https://www.facebook.com/sharer/sharer.php?u=" + cleanedUrl,
        },
        {
            socialMediaBrandName: "X",
            socialMediaLogo: xLogo,
            shareHref: "https://x.com/intent/post?url=" + cleanedUrl,
        },
        {
            socialMediaBrandName: "E-Mail",
            socialMediaLogo: mailLogo,
            shareHref: "mailto:?subject=''&body=" + cleanedUrl,
        },
    ];

    const handleOpen = () => {
        setOpenPopUp(true);
    };

    const clickOnLogo = (element: SocialMediaLinkInfo) => {
        if (element.havePopUp) {
            handleOpen();
        } else {
            window.open(element.shareHref, "_new");
        }
    };

    //Creating the <img>-Tag for viewpoint
    const socialMediaLinkImg = socialMediaLinkArray.map((element, index) => {
        return (
            <div style={{ width: "50px", height: "50px" }}>
                <Tooltip
                    key={index}
                    title={`Share ${element.socialMediaBrandName !== "E-Mail" ? "on" : "via"} ${
                        element.socialMediaBrandName
                    }`}
                >
                    <img
                        key={element.socialMediaBrandName}
                        src={element.socialMediaLogo}
                        alt={`"Share with ${element.socialMediaBrandName}"`}
                        onClick={() => clickOnLogo(element)}
                        style={{
                            width: "36px",
                            height: "36px",
                            padding: "0px",
                            margin: "7px",
                            cursor: "pointer",
                        }}
                    />
                </Tooltip>
            </div>
        );
    });

    return (
        <div
            style={{
                justifyContent: windowWidth < 450 ? "center" : "left",
                columnCount: certificationValidatorSizeHandle(windowWidth).shareButtonColumnCount,
                columnGap: 0,
            }}
        >
            {socialMediaLinkImg}
            <InstagramPopUp
                openPopUp={openPopUp}
                certificationPicture={props.certificationPicture}
                onClose={() => setOpenPopUp(false)}
            />
        </div>
    );
}
