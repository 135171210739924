import React, { ReactElement, useState, useEffect } from "react";
import { lastIndexOf } from "lodash";
import tinycolor from "tinycolor2";

// MUI
import theme from "../../theme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Theme, withStyles, makeStyles } from "@material-ui/core/styles";
import Slider, { Mark } from "@material-ui/core/Slider";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from "@material-ui/core/Tooltip";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

// Localization
import { useTranslation } from 'react-i18next';

interface SliderProps {
    onChange: any;
    // the slider value (1..8 for eight products)
    value: number | string;
    billingCycle: VbPricing.billingCycle;
    pricing: VbPricing.PricingObject;
    // Determines whether the marks below the slider shall be rendered
    hideLabels?: boolean;
    // whether to hide the text on top (How many certificates will you issue per year?) shall be rendered
    hideCaption?: boolean;
    // whether to hide the tooltip with the number of certificates that can be issued of each mark
    hideTooltip?: boolean;
    setSliderMarks?: any;
}

const _PricingSlider = withStyles({
    root: {
        color: theme.palette.vbBlue.main,
        height: 12,
        marginBottom: 0,
    },
    thumb: {
        height: 24,
        width: 24,
        backgroundColor: theme.palette.vbBlue.main,
        border: "3px solid white",
        boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.4)",
        marginTop: -7,
        marginLeft: -12,
        "&:focus, &$active": {
            boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.4)",
        },
        "&:hover": {
            boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.6)",
        },
    },
    active: {},
    valueLabel: {
        left: "calc(-50% + 4px)",
    },
    track: {
        height: 10,
        width: "100%",
        borderRadius: 8,
        margin: 1,
        backgroundColor: "59DFBF",
    },
    rail: {
        height: 11,
        width: "100%",
        borderRadius: 8,
        border: "solid 1px gray",
        backgroundColor: "white",
        opacity: 100,
    },
    mark: {
        color: theme.palette.grey[400],
        marginTop: 2,
        height: 7,
        width: 1,
        '&[data-index="0"]': {
            display: "none",
        },
        '&[data-index="7"]': {
            display: "none",
        },
    },
    markActive: {
        display: "none",
    },
    markLabel: {
        color: theme.palette.grey[400],
        marginTop: 10,
        fontWeight: 500,
    },
})(Slider);

interface ValueLabelProps {
    children: React.ReactElement;
    open: boolean;
    value: number;
}

const useStyles = makeStyles((theme: Theme) => ({
    arrow: {
        fontSize: 20,
        color: "white",
        "&::before": {
            border: "1px solid gray",
            backgroundColor: "white",
        },
    },
    tooltipSliderLabel: {
        fontSize: "0.9rem",
        backgroundColor: "white",
        color: "black",
        borderRadius: 6,
        border: "1px solid gray",
        width: 140,
        height: 64,
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: 600,
    },
    tooltipMore: {
        backgroundColor: "#2B435E",
        color: "white",
        fontSize: "1rem",
        textAlign: "center",
    },
    arrowMore: {
        fontSize: 20,
        color: "#08366D",
    },
}));

function ValueLabelComponent(props: ValueLabelProps): ReactElement {
    const { children, open, value } = props;
    const classes = useStyles();

    return (
        <Tooltip
            classes={{ arrow: classes.arrow, tooltip: classes.tooltipSliderLabel }}
            open={open}
            enterTouchDelay={0}
            placement="top"
            title={value}
            arrow
        >
            {children}
        </Tooltip>
    );
}

const assembleMarks = (
    pricing: VbPricing.PricingObject,
    cycle: VbPricing.billingCycle,
    labelCustom: ReactElement
): Mark[] => {
    let marks: Mark[] = [];
    let products =
        (cycle === "yearly" ? pricing.bundles.pro.products?.yearly : pricing.bundles.pro.products.monthly) ||
        pricing.bundles.pro.products.monthly;
    products.forEach((value: VbPricing.PricingStripeProduct, index: number) =>
        marks.push({ value: index + 1, label: value.participant_count })
    );

    // add label for custom
    marks.push({ value: products.length + 1, label: labelCustom });

    return marks;
};

interface LabelCustomProps {
    maxParticipantsInBillingCycle: number;
    billingCycle: VbPricing.billingCycle;
    value: string | number;
    marksLength: number;
    hideLabels?: boolean;
}

const LabelCustom = (props: LabelCustomProps): ReactElement => {
    const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
    const classes = useStyles();
    const { billingCycle } = props;
    const { t } = useTranslation();

    const year = t("common.pricingSlider.year", "year")
    const month = t("common.pricingSlider.month", "month")

    return (
        <div
            onMouseEnter={() => {
                setTooltipOpen(true);
            }}
            onMouseLeave={() => {
                setTooltipOpen(false);
            }}
        >
            <Tooltip
                title={t("common.pricingSlider.ifYouNeedMore", "If you need more than {{maxParticipantsInBillingCycle}} recipients per {{billingCycle}}, please contact us.", { maxParticipantsInBillingCycle: props.maxParticipantsInBillingCycle, billingCycle: billingCycle === "yearly" ? year : month }) as string}
                open={tooltipOpen || props.value === props.marksLength}
                classes={{ tooltip: classes.tooltipMore, arrow: classes.arrowMore }}
                arrow
            >
                <span className="d-flex align-items-center">
                    {!props.hideLabels && (
                        <>
                            &gt; {props.maxParticipantsInBillingCycle}{" "}
                            <HelpOutlineIcon style={{ fontSize: 16 }} className="ml-1" />
                        </>
                    )}
                </span>
            </Tooltip>
        </div>
    );
};

const PricingSlider = (props: SliderProps): ReactElement => {
    // state hooks
    // maxParticipantsInBillingCycle is needed for tooltip
    const [maxParticipantsInBillingCycle, setMaxParticipantsInBillingCycle] = useState<number>(0);
    // Indicator of how many recipients are selected
    const [marks, setMarks] = useState<Mark[]>([]);

    const updateMarks = () => {
        let _marks = assembleMarks(
            props.pricing,
            props.billingCycle,
            <LabelCustom
                maxParticipantsInBillingCycle={maxParticipantsInBillingCycle}
                billingCycle={props.billingCycle}
                value={props.value}
                marksLength={marks.length}
                hideLabels={props.hideLabels}
            />
        );
        setMarks(_marks);
        return _marks;
    };

    // misc. hooks
    const size__sm_down = useMediaQuery(theme.breakpoints.down("sm"));
    const size__xs_down = useMediaQuery(theme.breakpoints.down("xs"));
    const { t } = useTranslation();

    useEffect(() => {
        if (props.setSliderMarks) {
            props.setSliderMarks(updateMarks());
        } else {
            updateMarks();
        }
    }, [props.value, props.billingCycle, maxParticipantsInBillingCycle]);

    useEffect(() => {
        if (!marks) return;
        setMaxParticipantsInBillingCycle(Number(marks[marks.length - 2]?.label) || 0);
    }, [marks]);

    if (!marks) return <>Loading...</>;
    return size__sm_down ? (
        <div className="d-flex justify-content-center mt-4 mb-4">
            <div style={{ width: size__xs_down ? "100%" : "auto" }}>
                {!props.hideCaption && (
                    <div
                        style={{
                            width: "100%",
                            color: theme.palette.grey[800],
                            fontSize: 16,
                            letterSpacing: 1.2,
                            marginBottom: 8,
                        }}
                    >
                        {t("common.pricingSlider.howManyCertificatesDoYouIssue", "How many certificates do you issue?")}
                    </div>
                )}
                <Select
                    fullWidth
                    disableUnderline
                    value={props.value}
                    style={{
                        textAlign: "center",
                        borderRadius: 6,
                        border: `1px solid ${theme.palette.vbBlue.main}`,
                        fontSize: 16,
                        minWidth: 280,
                        padding: 5,
                        maxWidth: 388,
                        color: theme.palette.grey[900],
                        backgroundColor: tinycolor(theme.palette.vbBlue.light).lighten(53).toString(),
                        fontWeight: 500,
                    }}
                    onChange={(event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
                        props.onChange(event, event.target.value, marks);
                    }}
                >
                    {marks.map((mark) => {
                        return mark.value < marks.length ? (
                            <MenuItem key={`marks-${mark.value}`} value={mark.value}>
                                {mark.label} {t("common.pricingSlider.certsIssued", "certificates issued")}
                            </MenuItem>
                        ) : undefined;
                    })}
                    <MenuItem key={`marks-${lastIndexOf}`} value={marks[marks.length - 2].value + 1}>
                        &gt; {marks[marks.length - 2].label} {t("common.pricingSlider.certsIssued", "certificates issued")}
                    </MenuItem>
                </Select>
            </div>
        </div>
    ) : (
        <div className="d-flex justify-content-center">
            <div style={{ marginLeft: 16, marginRight: 16, width: "100%" }}>
                {!props.hideCaption && (
                    <span style={{ color: theme.palette.grey[800], fontSize: 16, letterSpacing: 1.2 }}>
                        {t("common.pricingSlider.howManyWillYouIssue", "How many certificates will you issue per")} {props.billingCycle === "yearly" ? t("common.pricingSlider.year", "year") : t("common.pricingSlider.month", "month")}?
                    </span>
                )}
                <_PricingSlider
                    valueLabelDisplay="off"
                    valueLabelFormat={(value) =>
                        value === marks.length
                            ? `Feel free to reach out if you need more!`
                            : `Up to ${marks
                                .find((mark) => mark.value === value)
                                ?.label?.toLocaleString()} certificates issued per ${props.billingCycle === "yearly" ? t("common.pricingSlider.year", "year") : t("common.pricingSlider.month", "month")
                            }`
                    }
                    ValueLabelComponent={props.hideTooltip ? undefined : ValueLabelComponent}
                    aria-label="pricing slider"
                    value={typeof props.value === "number" ? props.value : 0}
                    onChange={(event, newValue) => props.onChange(event, newValue, marks)}
                    step={1}
                    min={1}
                    max={marks.length}
                    marks={
                        props.hideLabels
                            ? marks.map((elem, idx) => {
                                // remove label to not display it
                                if (idx === marks.length - 1) return elem;
                                else return { value: elem.value };
                            })
                            : marks
                    }
                />
            </div>
        </div>
    );
};

export default PricingSlider;
