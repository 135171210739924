import { ReactElement } from "react";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import { ROUTES } from "../../utils/routes";

interface LinkCardProps {
    title: string;
    link: string;
}
interface NavigatorProps {}

const LinkCard = (props: LinkCardProps): ReactElement => {
    return (
        <div>
            <Link to={props.link} className="mr-2">
                {props.title}
            </Link>
        </div>
    );
};

export default function Navigator({}: NavigatorProps): ReactElement {
    if (!window.location.hostname.includes("localhost")) return <Redirect to={ROUTES.notfound} />;
    return (
        <div style={{ padding: "12px" }}>
            <LinkCard title="Pricing" link={ROUTES.pricing} />
            <LinkCard title="Certificate Validation" link={ROUTES.certificateValidator} />
            <LinkCard title="Certificate Validation - in iFrame (test)" link={"/test"} />
            <LinkCard title="LinkedIn Certificate Generator" link={ROUTES.linkedinCertificateLinkGenerator} />
            <LinkCard title="Register" link={ROUTES.register} />
            <LinkCard title="UTF-8 Converter" link={ROUTES.utf8converter} />
            <hr />
            <LinkCard
                title="Signup - Pro monthly - 100 recp. - 29 USD"
                link={"/register?plan=1JMXE7BlUauxnBUz6nXfHWwz"}
            />
            <LinkCard
                title="Signup - Expert yearly - 1800 recp. - 828 EUR"
                link={"/register?plan=1JMXVZBlUauxnBUzylwxKyrl"}
            />
        </div>
    );
}
