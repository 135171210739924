import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// languages
import translation_en from "./locales/en/translation.json";
import translation_de from "./locales/de/translation.json";

i18n.use(LanguageDetector).init({
    resources: {
        en: {
            translations: translation_en,
        },
        de: {
            translations: translation_de,
        },
    },
    fallbackLng: "en",
    debug: false,

    // common namespace around full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: ".",

    interpolation: {
        escapeValue: false, // not needed for react!!
        formatSeparator: ",",
    },

    react: {
        wait: true,
    },

});

export default i18n;
