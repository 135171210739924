import { FC } from "react";

import { COMPLEX_SHADOW } from "./pagesCarousel.constants";
import CarouselItemWrapper from "./CarouselItemWrapper";

interface CarouselItemProps {
    src: string;
    alt: string;
    position: "left" | "center" | "right";
    itemWidth: number; // Is used as percentage value in flex property.
}

const CarouselItem: FC<CarouselItemProps> = ({ src, alt, position, itemWidth }) => (
    <CarouselItemWrapper itemWidth={itemWidth}>
        <img
            src={src}
            alt={alt}
            loading={alt === "Slide 1" ? "eager" : "lazy"}
            style={{
                boxShadow: COMPLEX_SHADOW,
                opacity: position === "center" ? 1 : 0.3,
                transition: "opacity 0.5s ease-in-out",
            }}
        />
    </CarouselItemWrapper>
);

export default CarouselItem;
