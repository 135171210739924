import React from "react";
import { h1Style, billingSwitchSelected, billingSwitchUnselected } from "./styles";
import theme from "../../theme";
import type { Mark } from "@material-ui/core/Slider";

// Localization
import { useTranslation } from 'react-i18next';

type Props = {
    numSelectedParticipants: number;
    sliderMarks: Mark[] | undefined;
    sliderValue: number;
    billingCycle: VbPricing.billingCycle;
    maximumParticipantsInBillingyCycle: {
        monthly: number;
        yearly: number;
    };
    setBillingCycle: React.Dispatch<React.SetStateAction<VbPricing.billingCycle>>;
    size: "normal" | "large";
};

export default function BillingSwitch(props: Props) {
    const { t } = useTranslation();
    return (
        <div>
            <p className="text-center mb-0 user-select-none">
                <span style={{ display: "inline-block", marginRight: 8 }}>
                    {props.numSelectedParticipants === -1 && props.sliderMarks?.length === props.sliderValue
                        ? t("common.planSelection.moreThan", "More than")
                        : t("common.planSelection.upTo", "Up to")}
                </span>
                <span
                    style={{
                        ...h1Style,
                        width: props.size === "large" ? 104 : 84,
                        display: "inline-block",
                        marginBottom: 0,
                    }}
                >
                    <span style={{ fontSize: props.size === "large" ? "2.7rem" : "inherit" }}>
                        {/* get maximum of last occurence if custom*/}
                        {props.numSelectedParticipants === -1 && props.sliderMarks?.length === props.sliderValue
                            ? props.billingCycle === "monthly"
                                ? props.maximumParticipantsInBillingyCycle.monthly
                                : props.maximumParticipantsInBillingyCycle.yearly
                            : props.numSelectedParticipants}
                    </span>
                </span>
                <span style={{ display: "inline-block", marginLeft: 8 }}>{t("common.planSelection.per", "per")}</span>
            </p>
            <p className="mb-4 text-center user-select-none">
                {[
                    {
                        cycle: "monthly" as VbPricing.billingCycle,
                        text: t("common.planSelection.month", "month"),
                    },
                    {
                        cycle: "yearly" as VbPricing.billingCycle,
                        text: t("common.planSelection.year", "year"),

                    },
                ].map((elem, index) => (
                    <React.Fragment key={index}>
                        <span
                            className="d-inline-block"
                            role="button"
                            onClick={() => props.setBillingCycle(elem.cycle)}
                            style={props.billingCycle === elem.cycle ? billingSwitchSelected : billingSwitchUnselected}
                        >
                            {elem.text}
                        </span>
                        {index === 0 && <span style={{ color: theme.palette.grey[500] }}>|</span>}
                    </React.Fragment>
                ))}
            </p>
        </div>
    );
}
