import React, { ReactElement, useEffect } from "react";
import PlanSelection from "../../Common/PlanSelection/PlanSelection";
import { useBrand } from "../../../utils/customHooks";

// mui
import theme from "../../theme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Skeleton from "@material-ui/lab/Skeleton";
import Grid from "@material-ui/core/Grid";

// redux
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { getPricingObject } from "../../../actions/pricing";

// styles
import { skeletonPlanSelection, negativeMarginTopDesktop } from "./utils/styles";

interface Props {}

/**
 * This component loads the pricing object into store and then mounts the respective child components
 * @param param
 * @returns
 */
export default function PlanSelectionWrapper({}: Props): ReactElement {
    // redux
    const { pricing } = useSelector((state: RootState) => ({
        pricing: state.pricing.pricing,
    }));
    const dispatch = useDispatch();
    const size__sm_down = useMediaQuery(theme.breakpoints.down("sm"));
    const brandManager = useBrand();

    useEffect(() => {
        // load pricing
        dispatch(getPricingObject(brandManager.getAccountType()));
    }, []);

    return (
        <div
            style={{
                maxWidth: 1200,
                margin: "auto",
                padding: size__sm_down ? "20px" : "20px 40px",
            }}
            className="hide-scrollbar open-sans"
        >
            {/* <div> */}
            {pricing ? (
                <div>
                    <PlanSelection pricing={pricing} />
                </div>
            ) : (
                <>
                    <div
                        className="d-flex justify-content-center"
                        style={{ marginTop: size__sm_down ? 0 : negativeMarginTopDesktop }}
                    >
                        <Skeleton variant="rect" width={320} height={108} />
                    </div>
                    <div style={{ marginBottom: 64 }} />
                    {size__sm_down ? (
                        <div className="d-flex justify-content-center">
                            <Skeleton variant="rect" height={42} style={skeletonPlanSelection} />
                        </div>
                    ) : (
                        <div>
                            <Skeleton variant="rect" width={"100%"} height={42} />
                        </div>
                    )}
                    <div style={{ margin: `${size__sm_down ? 24 : `${58 - negativeMarginTopDesktop}px`} 0` }} />
                    <Grid container spacing={2} justify="space-between">
                        {Array(3)
                            .fill(1)
                            .map((val, idx) => {
                                return (
                                    <Grid
                                        key={`skeleton-${idx}`}
                                        item
                                        xs={12}
                                        md={4}
                                        className="d-flex justify-content-center w-100"
                                    >
                                        <Skeleton variant="rect" height={512} style={skeletonPlanSelection} />
                                    </Grid>
                                );
                            })}
                    </Grid>
                </>
            )}
        </div>
    );
}
