import React, { useLayoutEffect, useState } from "react";
import BrandManager, { getBrandFromLocation } from "../utils/brandManager";

/**
 * After import use with
 *      const [windowWidth, windowHeight] = useWindowSize();
 * @returns number[]
 */
export function useWindowSize(): number[] {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener("resize", updateSize);
        updateSize();
        return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
}

/**
 * Custom hook for brand. E.g. use as so:
 *      const brandManager = useBrand();
 * @returns
 */
export function useBrand(): BrandManager {
    // use lazy initial: https://reactjs.org/docs/hooks-reference.html#lazy-initial-state
    const [BrandManagerRef] = useState<BrandManager>(
        () => new BrandManager(getBrandFromLocation(window.location.hostname))
    );
    return BrandManagerRef;
}

/**
 * A custom hook that builds on useLocation to parse
 * the query string for you.
 *
 * @returns
 */
export function useQuery() {
    // do not use useLocation hook here, because AppNoRouter.tsx does not use the react-router. Then this hook won't work!
    const search = window.location.search;

    return React.useMemo(() => new URLSearchParams(search), [search]);
}
