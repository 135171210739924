import { Box, styled } from "@material-ui/core";
import Indicators from "./CarouselIndicators";
import CarouselButton from "./CarouselButton";
import CarouselItem from "./CarouselItem";
import usePagesCarousel from "./pagesCarousel.hooks";
import { ITEM_WIDTH_PERCENTAGE } from "./pagesCarousel.constants";

const CarouselContainer = styled(Box)({
    overflow: "hidden",
    position: "relative",
    width: "100%",
    maxWidth: "800px",
    margin: "0 auto",
    padding: "7px 0",
});

interface PageCarouselProps {
    pages: string[];
}

const PagesCarousel = ({ pages }: PageCarouselProps) => {
    const { trackRef, isDraggingRef, startDrag, drag, state, onClickNext, onClickPrev, setNextSlide } =
        usePagesCarousel(pages);

    return (
        <Box position="relative">
            <CarouselContainer>
                <div
                    ref={trackRef}
                    style={{
                        display: "flex",
                        transition: isDraggingRef.current ? "none" : "transform 0.3s ease-out",
                        cursor: isDraggingRef.current ? "grabbing" : "grab",
                        userSelect: "none",
                    }}
                    onMouseDown={startDrag}
                    onMouseMove={drag}
                    onTouchStart={startDrag}
                    onTouchMove={drag}
                >
                    {pages.map((page, index) => (
                        <CarouselItem
                            key={`Slide ${index + 1}`}
                            src={page}
                            alt={`Slide ${index + 1}`}
                            position={index === state.active ? "center" : index < state.active ? "left" : "right"}
                            itemWidth={ITEM_WIDTH_PERCENTAGE}
                        />
                    ))}
                </div>
                <CarouselButton position="left" onClick={onClickPrev} disabled={state.active === 0} />
                <CarouselButton position="right" onClick={onClickNext} disabled={state.active === pages.length - 1} />
            </CarouselContainer>
            <Indicators length={pages.length} active={state.active} onClick={setNextSlide} />
        </Box>
    );
};

export default PagesCarousel;
