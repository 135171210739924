import React from "react";
import { makeStyles } from "@material-ui/core";
import tinycolor from "tinycolor2";
import clsx from "clsx";

const COLORS = {
    STARTER: "#ffd51e",
    PRO: "#005CEF",
    EXPERT: "#309E3A",
    CUSTOM: "#B152EC",
};

interface IInfoBadgeProps {
    text: string;
    color?: string;
    className?: any;
    style?: React.CSSProperties;
    onClick?: any;
}

const useStyles = makeStyles((theme) => ({
    infoBadge: {
        border: "solid 1px",
        borderRadius: 5,
        textAlign: "center",
        fontWeight: 600,
        fontSize: "0.85rem",
        //display: "inline-flex",
        paddingRight: 12,
        paddingLeft: 12,
        position: "absolute",
        top: 10,
        right: 14,
        display: "flex",
        alignItems: "center",
    },
}));

function InfoBadge(props: IInfoBadgeProps) {
    const { text, color, className, style, onClick } = props;
    const classes = useStyles();

    let _color = color || COLORS.STARTER;

    let darkerColor = tinycolor(_color).darken(25).toString();
    let borderColor = tinycolor(_color).brighten(25).toString();
    if (tinycolor(borderColor).isLight()) borderColor = tinycolor(_color).darken(25).toString();
    let backgroundColor = tinycolor(_color).setAlpha(0.05).toString();

    return (
        <span
            className={clsx(className, classes.infoBadge)}
            style={{ ...style, color: darkerColor, borderColor: borderColor, backgroundColor: backgroundColor }}
            onClick={onClick}
        >
            {text}
        </span>
    );
}

export default InfoBadge;
