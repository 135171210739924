import React, { ReactElement } from "react";
import { Route, RouteProps, Redirect } from "react-router-dom";
import { ROUTES } from "../../../utils/routes";
import { ALLOWED_HOSTS } from "./utils";

interface PublicRouteProps extends RouteProps {
    // tslint:disable-next-line:no-any
    component: any;
    propsForComponent?: object;
    // All other props
    [x: string]: any;
}

export default function PublicRoute(props: PublicRouteProps): ReactElement {
    console.log("window.location.origin", window.location.origin);

    // TODO: Test this!!!!! Also add the domain of AZure?
    if (!ALLOWED_HOSTS.includes(window.location.origin) && window.location.pathname !== ROUTES.notfound) {
        console.error("Origin not in allowed hosts.");
        return <Redirect to={ROUTES.notfound} />;
    }

    const { component: Component, ...rest } = props;

    return <Route {...rest} render={(props) => <Component {...props} />} />;
}
