import {
    START_LOADING,
    STOP_LOADING,
    START_CERTIFICATE_DETAILS_LOADING,
    STOP_CERTIFICATE_DETAILS_LOADING,
    START_REGISTER_LOADING,
    STOP_REGISTER_LOADING,
} from "./types";

export const startLoading = () => {
    return {
        type: START_LOADING,
        payload: true,
    };
};

export const stopLoading = () => {
    return {
        type: STOP_LOADING,
        payload: false,
    };
};

export const startCertificateLoading = () => {
    return {
        type: START_CERTIFICATE_DETAILS_LOADING,
        payload: true,
    };
};

export const stopCertificateLoading = () => {
    return {
        type: STOP_CERTIFICATE_DETAILS_LOADING,
        payload: false,
    };
};

export const startRegisterLoading = () => {
    return {
        type: START_REGISTER_LOADING,
        payload: true,
    };
};

export const stopRegisterLoading = () => {
    return {
        type: STOP_REGISTER_LOADING,
        payload: false,
    };
};
