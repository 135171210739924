export function cleanUp(toClean: string) {
    const cleanedString = toClean
        .replaceAll("=", "%3d")
        .replaceAll("/", "%2f")
        .replaceAll(".", "%2e")
        .replaceAll("-", "%2d")
        .replaceAll(":", "%3a")
        .replaceAll(" ", "%20")
        .replaceAll("_", "%5f")
        .replaceAll("?", "%3f")
        .replaceAll("!", "%21");
    return cleanedString;
}

/* 
* Function to clean a string for problemless post throu url and href
*
* To use the function include this in youre code
*
import cleanUp from "./cleanStringIntoHTML";

cleanUp(xyz);
*
* xyz is the string you want to cleanup
*
*/
