// messages
export const CREATE_MESSAGE = "CREATE_MESSAGE";
export const CLEAR_MESSAGES = "CLEAR_MESSAGES";

// certificate validator
export const GET_CERTIFICATE_DETAILS = "GET_CERTIFICATE_DETAILS";
export const CLEAR_CERTIFICATE_DETAILS = "CLEAR_CERTIFICATE_DETAILS";
export const SET_CERTIFICATE_ERROR = "SET_CERTIFICATE_ERROR";
export const SET_PDF_VALIDITY = "SET_PDF_VALIDITY";

// loading
export const START_LOADING = "START_LOADING";
export const STOP_LOADING = "STOP_LOADING";
export const START_CERTIFICATE_DETAILS_LOADING = "START_CERTIFICATE_DETAILS_LOADING";
export const STOP_CERTIFICATE_DETAILS_LOADING = "START_CERTIFICATE_DETAILS_LOADING";
export const START_REGISTER_LOADING = "START_REGISTER_LOADING";
export const STOP_REGISTER_LOADING = "STOP_REGISTER_LOADING";

// pricing
export const GET_PRICING_OBJECT = "GET_PRICING_OBJECT";
