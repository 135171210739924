import ipLocation from "iplocation";
import publicIp from "public-ip";
import { supportedCountryCodes } from "./static/taxIdsByCountry";

/**
 *
 * Common
 *
 */

/**
 *
 * @param cent_amount
 * @param factor optional. Number to multiply the cent_amount param with. Defaults to 1
 * @returns
 */
export const centsToLocaleString = (cent_amount: number, factor: number = 1, showDecimals: boolean = true) => {
    let amount = Math.round(cent_amount * factor) / 100;
    return showDecimals ? amount.toFixed(2) : amount;
};

/**
 *
 * PLAN SELECTION library
 *
 */

/**
 * Check if plans with more recipients have the same stripe id. If yes, set the number of
 * recipients to that higher number.
 *
 * How it works
 *    Get the selected recipient count
 *    If it is undefined
 *        set participantCountToDisplay to "-"
 *    Else
 *        set index = 0
 *        Iterate over subsequent element in pricing
 *            If price id of currency is the same:
 *                Save index
 *                Continue
 *            Else
 *                Use index to set participantCountToDisplay
 *
 * @param products
 * @param numSelectedParticipants The actual selected number of participants on the slider
 * @param selectedCurrency The selected or set currency
 * @param setterFct setter Function for the variable that is determined
 */
export const determineParticipantCountToDisplayForPlan = (
    products: VbPricing.PricingStripeProduct[] | undefined,
    numSelectedParticipants: number,
    selectedCurrency: VbPricing.currencyType,
    setterFct: (value: React.SetStateAction<string | number>) => void
) => {
    if (products) {
        let selectedStripePriceId: string | undefined = "";
        let selectedParticipantCount = products.find((product, _index) => {
            if (product.participant_count === numSelectedParticipants) {
                selectedStripePriceId = product.prices.find(
                    (elem) => elem.currency === selectedCurrency
                )?.stripe_price_id;
                return true;
            }
            return false;
        })?.participant_count;
        if (!selectedParticipantCount) setterFct("-");
        else {
            let _participantCountToDisplayCached = selectedParticipantCount;
            for (let idx = 0; idx < products.length; idx++) {
                // only compare stripe id if recipient count is larger and subsequent element exists
                if (products[idx].participant_count > selectedParticipantCount) {
                    // get stripe id
                    let _productToCompareTo = products[idx];
                    let _stripe_price_id = _productToCompareTo.prices.find(
                        (elem) => elem.currency === selectedCurrency
                    )?.stripe_price_id;
                    // set participants if ids match
                    if (_stripe_price_id && _stripe_price_id === selectedStripePriceId) {
                        _participantCountToDisplayCached = _productToCompareTo.participant_count;
                    }
                }
            }
            setterFct(_participantCountToDisplayCached);
        }
    }
};

/**
 * Get the  rounded (no decimals) monthly price for a plan
 * Math.round is needed because no decimals shall be shown in this pricing
 *
 * @param pricing
 * @param numSelectedParticipants
 * @param selectedCurrency
 * @param billingCycle
 * @returns
 */
export const getRoundedMonthlyPrice = (
    pricing: VbPricing.PricingSingleBundle,
    numSelectedParticipants: number,
    selectedCurrency: VbPricing.currencyType,
    billingCycle: VbPricing.billingCycle
): number => {
    return Math.round(
        (pricing.products[billingCycle]
            ?.find((product) => product.participant_count === numSelectedParticipants)
            ?.prices.find((price) => price.currency === selectedCurrency)?.amount || 0) /
            100 /
            (billingCycle === "yearly" ? 12 : 1)
    );
};

/**
 * Determine the initial value for the recipient slider
 *
 * @param pricing
 * @param billingCycle
 * @param defaultBilling
 * @returns
 */
export const getInitialSliderValue = (
    pricing: VbPricing.PricingObject,
    billingCycle: VbPricing.billingCycle,
    defaultBilling?: any
): number => {
    if (!defaultBilling) return 1;
    let _index = pricing.bundles.pro.products[billingCycle]?.findIndex(
        (elem) => elem.participant_count === defaultBilling.numSelectedParticipants
    );
    if (_index === undefined || _index === -1) return 1;
    else return _index + 1;
};

/**
 * Method to get the country code from location
 *
 * @returns
 */
export const getCountryCodeByIpLocation = async (): Promise<string> => {
    let location = await getLocation();

    if (!("country" in location)) return "DE";
    // check if country code is supported. Else fallback to DE (if continent = EU), else US
    console.log("[getCountryCodeByIpLocation()] location.country: ", location.country);
    if (location.country.code in supportedCountryCodes) return location.country.code;
    else if (location.continent.code === "EU") return "DE";
    else return "US";
};

/**
 * Method to get the location based on the user's ip address
 *
 * @returns
 */
export const getLocation = async (): Promise<ipLocation.ReturnType> => {
    let ipAddress = await publicIp.v4();
    console.log("[getLocation()] Determined ipAddress:", ipAddress);

    let location = await ipLocation(ipAddress);

    console.log("[getLocation()] Use location from IP: ", location);
    return location;
};

// Register page utils of plan selection

/**
 * Get the currently selected price using the selectedPriceId
 * @param selectedPriceId
 * @param plans
 * @returns
 */
export const getPlanByPriceId = (
    selectedPriceId: string,
    plans: VbPricing.PricingObject
): ReduxStoreRegister.ISelectedPriceObjectWithMetadata | undefined => {
    let returnObject = undefined;

    // iterate over all plans in the bundle object
    for (const [key, value] of Object.entries(plans.bundles)) {
        // `key` holds the plan (starter, expert pro), `value` the single pricing bundle
        let products = (value as VbPricing.PricingSingleBundle).products;
        // check monthly
        for (let product of products.monthly) {
            let price = product.prices.find((elem) => elem.stripe_price_id === selectedPriceId);
            if (price)
                returnObject = {
                    planName: key as VbPricing.bundleNamesStandard,
                    billingCycle: "monthly" as "monthly",
                    currency: price.currency,
                    numSelectedParticipants: product.participant_count,
                    priceObject: price,
                };
        }
        // check yearly prices
        if (products.yearly) {
            for (let product of products.yearly) {
                let price = product.prices.find((elem) => elem.stripe_price_id === selectedPriceId);
                if (price)
                    returnObject = {
                        planName: key as VbPricing.bundleNamesStandard,
                        billingCycle: "yearly" as "yearly",
                        currency: price.currency,
                        numSelectedParticipants: product.participant_count,
                        priceObject: price,
                    };
            }
        }
    }
    return returnObject;
};

/**
 * Returns a string for the currency that is rendered to the dom
 * @param type
 * @returns
 */
export const renderCurrency = (currency: VbPricing.currencyType, type: "symbol" | "three-digit"): string => {
    let res = "";
    switch (currency) {
        case "brl":
            res = type === "symbol" ? "R$" : "BRL";
            break;
        case "usd":
            res = type === "symbol" ? "$" : "USD";
            break;
        default:
        case "eur":
            res = type === "symbol" ? "€" : "EUR";
            break;
    }
    return res;
};

/**
 * Returns the billing cycle to display like "per xxx"
 * @param billingCycle
 * @returns
 */
export const getBillingCycleToDisplay = (billingCycle: VbPricing.billingCycle) => {
    switch (billingCycle) {
        case "monthly":
            return "month";
        case "yearly":
        default:
            return "year";
    }
};
