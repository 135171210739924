import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useBrand } from "../../utils/customHooks";

interface Props {}

export default function NotFound({}: Props): ReactElement {
    const BrandManagerRef = useBrand();
    const { t } = useTranslation();

    return (
        <div
            style={{
                display: "inline-block",
                backgroundColor: "#59DFBF",
                height: "100vh",
                width: "100vw",
                margin: 0,
                color: "white",
                padding: ".6em 3rem",
            }}
        >
            <h1 style={{ fontSize: "10rem" }}>Whoops!</h1>
            <p
                style={{
                    display: "block",
                    fontSize: "3.4rem",
                }}
            >
                {t("misc.not_found.heading")}
            </p>
            <p
                style={{
                    marginTop: "3em",
                    display: "block",
                    fontSize: "2rem",
                }}
            >
                {t("misc.not_found.heading")}:{" "}
                <a
                    href={BrandManagerRef.getBrandUrl()}
                    target="_blank"
                    style={{ color: "inherit", textDecoration: "underline" }}
                >
                    {BrandManagerRef.getBrandName()}
                </a>
            </p>
        </div>
    );
}
