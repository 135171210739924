import { Box } from "@material-ui/core";
import React from "react";
import * as styles from "./LinkTranslated.styles";

/**
 * * this component is used to translate the link component because
 * * i18n does not support a/Link components directly inside the Trans component
 * @interface ILinkTranslatedProps
 * @param {string} moveTo - path to move to
 * @param {React.ReactNode} children - children to render
 * @returns {JSX.Element}
 */
const LinkTranslated: React.FC<ILinkTranslatedProps> = ({ moveTo, newTab, children, insideTransComponent }) => {
    if (newTab)
        return (
            <Box
                style={{ ...styles.LinkContainer, margin: `0px ${insideTransComponent ? "4px" : "0px"}` }}
                onClick={() => window.open(moveTo)}
            >
                {children}
            </Box>
        );
    return (
        <a href={moveTo} style={styles.LinkContainer}>
            {children}
        </a>
    );
};

export default LinkTranslated;
