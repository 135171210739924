import React from "react";
import { billingSwitchSelected, billingSwitchUnselected } from "./styles";
import theme from "../../theme";

type Props = {
    selectedCurrency: VbPricing.currencyType;
    setSelectedCurrency: (currency: VbPricing.currencyType) => any;
    size: "small" | "large";
};

export default function CurrencyToggle(props: Props) {
    return (
        <div className="user-select-none" style={{ fontSize: props.size === "large" ? 16 : 13 }}>
            {[
                {
                    currency: "usd" as VbPricing.currencyType,
                    text: "USD",
                },
                {
                    currency: "eur" as VbPricing.currencyType,
                    text: "EUR",
                },
            ].map((elem, index) => (
                <React.Fragment key={index}>
                    <div
                        className="d-inline-block"
                        role="button"
                        onClick={() => props.setSelectedCurrency(elem.currency)}
                        style={{
                            ...(props.selectedCurrency === elem.currency
                                ? billingSwitchSelected
                                : billingSwitchUnselected),
                            width: 30,
                            margin: "0 4px",
                        }}
                    >
                        {elem.text}
                    </div>
                    {index === 0 && <span style={{ color: theme.palette.grey[500] }}>|</span>}
                </React.Fragment>
            ))}
        </div>
    );
}
