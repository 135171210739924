import axios from "axios";
import { AppDispatch } from "../redux/store";
import { startRegisterLoading, stopRegisterLoading } from "./loading";
import { createMessage } from "./messages";
import { getBackendUrl } from "../utils/routes";
import { getCookie } from "../utils/utils";

// REGISTER USER
export const register =
    (
        body: {
            user: any;
            orga: any;
            duration: number;
        },
        brandName: TAccountType,
        callback: any = undefined,
        successCallback: any = undefined,
        environment: TEnv = "prod"
    ) =>
    (dispatch: AppDispatch) => {
        // Determine API url
        const API_URL = getBackendUrl(environment, brandName);
        console.log(API_URL);

        // Activate linear progress bar on register page.
        dispatch(startRegisterLoading());

        // Headers
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        // get selected plan
        const regex = /price_([A-Za-z0-9]+)/;
        const match = window.location.search.match(regex);
        let priceId;
        if (match) {
            priceId = match[1];
            console.log(priceId);
        }

        const reqBody = {
            gtm_campaign_url: getCookie("__gtm_campaign_url"),
            gtm_referrer: getCookie("__gtm_referrer"),
            selected_plan: priceId,
            ...body,
        };

        // Try to get saasmantra query param
        const urlParams = new URLSearchParams(window.location.search);
        const saasmantraParam = urlParams.get("saasmantra");

        axios
            .post(
                `${API_URL}/api/auth/register/user?redirect=true${saasmantraParam ? "&saasmantra=true" : ""}`,
                reqBody,
                config
            )
            .then((res) => {
                console.log(res.data);
                if ("redirectLink" in res.data) {
                    // add price id to parameter
                    let redirectLink = res.data.redirectLink;
                    window.location.href = redirectLink;
                }

                if (successCallback) successCallback();

                // Track conversion in reditus
                window.gr("track", "conversion", { email: body.user.email });
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 500) {
                    dispatch(
                        createMessage(
                            "Oops, something did not work. Please contact us at help@virtualbadge.io",
                            "error",
                            10000
                        )
                    );
                } else if (err.response.status === 409) {
                    dispatch(
                        createMessage(
                            `This email is already registered. ${
                                err.response.data["resend_activation"] ? "Please check your inbox" : ""
                            }`,
                            "info",
                            10000
                        )
                    );
                } else {
                    dispatch(
                        createMessage(
                            "Oops, something did not work. Please contact us at help@virtualbadge.io",
                            "warning",
                            10000
                        )
                    );
                }
            })
            .finally(() => {
                dispatch(stopRegisterLoading());
                if (callback) callback();
            });
    };
