import React, { ReactElement } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

interface Props {
    message?: string;
    marginTop?: string;
    noMessage?: boolean;
}
function LoadingOverlay(props: Props): ReactElement {
    return (
        <div
            style={{ marginTop: props.marginTop || "30vh" }}
            className="d-flex justify-content-center flex-column align-items-center"
        >
            <CircularProgress />
            {props.noMessage ? null : <p style={{ marginTop: "2rem" }}> {props.message}</p>}
        </div>
    );
}

export default LoadingOverlay;
