import { useState } from "react";
import { IconButton } from "@material-ui/core";
import OpenInNewIcon from "../../../Assets/Images/Apps/CertificateValidator/OpenInNewIcon";
import PreviewDialog from "./PreviewDialog";

interface Props {
    certificateDetails: ICertificateDetails;
}

const EnlargeCertificate = ({ certificateDetails }: Props) => {
    const { badge_url, certificate_pages } = certificateDetails;
    const [open, setOpen] = useState(false);

    const previewContent = ((certificate_pages ?? []).length > 0 ? certificate_pages : [badge_url]) ?? [];

    return (
        <>
            <IconButton
                aria-label="enlarge"
                size="medium"
                style={{ position: "absolute", left: 2, bottom: 2 }}
                onClick={() => setOpen(true)}
            >
                <OpenInNewIcon />
            </IconButton>
            <PreviewDialog open={open} handleOnClose={() => setOpen(false)} previewContent={previewContent} />
        </>
    );
};

export default EnlargeCertificate;
