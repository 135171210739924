export const ROUTES = {
    navigator: "/",
    pricing: "/pricing",
    certificateValidator: "/certificate-validator",
    linkedinCertificateLinkGenerator: "/linkedin-certificate-link-generator",
    linkedinCertificateLinkGeneratorDe: "/de/linkedin-certificate-link-generator",
    utf8converter: "/utf8-converter",
    register: "/register",
    notfound: "/404",
};

export const getBackendUrl = (env: TEnv, brandName: TAccountType) => {
    if (["localhost", "127.0.0.1"].includes(window.location.hostname)) return "http://localhost:8000";

    if (localStorage.getItem("backendUrl")) return localStorage.getItem("backendUrl");

    switch (env) {
        case "local":
            return "http://localhost:8000";
        case "development":
            return "https://dev.api.virtualbadge.io";
        case "staging":
            return "https://stage.api.virtualbadge.io";
        default:
            if (brandName === "schulungszertifikate") return "https://api.schulungszertifikate.de";
            else return "https://api.virtualbadge.io";
    }
};
